import React, { useState } from 'react'

import PropTypes from 'prop-types'

import './home-serv-cloud.css'

const HomeServCloud = (props) => {
  const [activeTab, setActiveTab] = useState(0)
  return (
    <div className={`thq-section-padding ${props.rootClassName} `}>
      <div className="home-serv-cloud-container1 thq-section-max-width">
        <div className="home-serv-cloud-image-container">
          {activeTab === 0 && (
            <img
              alt={props.feature1ImgAlt}
              src={props.feature1ImgSrc}
              className="home-serv-cloud-image thq-img-ratio-16-9"
            />
          )}
          {activeTab === 1 && (
            <img
              alt={props.feature2ImgAlt}
              src={props.feature2ImgSrc}
              className="home-serv-cloud-image1 thq-img-ratio-16-9"
            />
          )}
          {activeTab === 2 && (
            <img
              alt={props.feature3ImgAlt}
              src={props.feature3ImgSrc}
              className="home-serv-cloud-image2 thq-img-ratio-16-9"
            />
          )}
        </div>
        <div className="home-serv-cloud-tabs-menu">
          <div
            onClick={() => setActiveTab(0)}
            className="home-serv-cloud-tab-horizontal"
          >
            <div className="home-serv-cloud-divider-container">
              {activeTab === 0 && (
                <div className="home-serv-cloud-container2"></div>
              )}
            </div>
            <div className="home-serv-cloud-content">
              <h2 className="thq-heading-2">{props.feature1Title}</h2>
              <span className="thq-body-small">
                {props.feature1Description}
              </span>
            </div>
          </div>
          <div
            onClick={() => setActiveTab(1)}
            className="home-serv-cloud-tab-horizontal1"
          >
            <div className="home-serv-cloud-divider-container1">
              {activeTab === 1 && (
                <div className="home-serv-cloud-container3"></div>
              )}
            </div>
            <div className="home-serv-cloud-content1">
              <h2 className="thq-heading-2">{props.feature2Title}</h2>
              <span className="thq-body-small">
                {props.feature2Description}
              </span>
            </div>
          </div>
          <div
            onClick={() => setActiveTab(2)}
            className="home-serv-cloud-tab-horizontal2"
          >
            <div className="home-serv-cloud-divider-container2">
              {activeTab === 2 && (
                <div className="home-serv-cloud-container4"></div>
              )}
            </div>
            <div className="home-serv-cloud-content2">
              <h2 className="thq-heading-2">{props.feature3Title}</h2>
              <span className="thq-body-small">
                {props.feature3Description}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

HomeServCloud.defaultProps = {
  feature2ImgAlt: 'SEO Image',
  feature2Description:
    'Migrate from one cloud to another, on-premises to cloud, or hybrid infrastructure to suit your needs with our expert guidance.',
  feature3Title: 'Architecture & Cost Optimization',
  feature2Title: 'Cloud Migration',
  feature2ImgSrc:
    'https://images.unsplash.com/photo-1517292987719-0369a794ec0f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMTA1OTc2NHw&ixlib=rb-4.0.3&q=80&w=1500',
  feature1ImgSrc: '/cloud%20services-1500w.webp',
  feature3ImgSrc:
    'https://images.unsplash.com/photo-1556761175-5973dc0f32e7?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMTA1OTc2Mnw&ixlib=rb-4.0.3&q=80&w=1500',
  feature1Title: 'Multi Platform Support',
  rootClassName: '',
  feature3Description:
    'Get your existing cloud services optimized for better performance and savings.',
  feature1Description:
    'Tailored solutions across various platforms like Google Cloud Platform (GCP), Microsoft Azure, AWS (Amazon Web Services), and more.',
  feature1ImgAlt: 'Digital Marketing Strategy',
  feature3ImgAlt: 'Social Media Marketing Image',
}

HomeServCloud.propTypes = {
  feature2ImgAlt: PropTypes.string,
  feature2Description: PropTypes.string,
  feature3Title: PropTypes.string,
  feature2Title: PropTypes.string,
  feature2ImgSrc: PropTypes.string,
  feature1ImgSrc: PropTypes.string,
  feature3ImgSrc: PropTypes.string,
  feature1Title: PropTypes.string,
  rootClassName: PropTypes.string,
  feature3Description: PropTypes.string,
  feature1Description: PropTypes.string,
  feature1ImgAlt: PropTypes.string,
  feature3ImgAlt: PropTypes.string,
}

export default HomeServCloud
