import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './home-contact-card.css'

const HomeContactCard = (props) => {
  return (
    <div className={`thq-section-padding ${props.rootClassName} `}>
      <div className="thq-section-max-width">
        <div className="home-contact-card-accent2-bg">
          <div className="home-contact-card-accent1-bg">
            <div className="home-contact-card-container1">
              <div className="home-contact-card-content">
                <span className="home-contact-card-text thq-heading-2">
                  {props.heading1}
                </span>
                <p className="home-contact-card-text1 thq-body-large">
                  {props.content1}
                </p>
              </div>
              <div className="home-contact-card-actions">
                <Link
                  to="/contact-us"
                  className="home-contact-card-navlink thq-button-filled"
                >
                  {props.action1}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

HomeContactCard.defaultProps = {
  action1: 'Get in touch today',
  heading1: 'Ready to boost your digital footprint?',
  rootClassName: '',
  content1: 'Let our expert team help you propel towards your vision.',
}

HomeContactCard.propTypes = {
  action1: PropTypes.string,
  heading1: PropTypes.string,
  rootClassName: PropTypes.string,
  content1: PropTypes.string,
}

export default HomeContactCard
