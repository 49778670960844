import React, { Fragment } from "react";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import ServicesHeader from "../components/services-header";
import ServicesFeatures from "../components/services-features";
import Gallery7 from "../components/gallery7";
import Team14 from "../components/team14";
import Contact3 from "../components/contact3";
import Footer from "../components/footer";
import "./services.css";

const Services = (props) => {
  return (
    <div className="services-container">
      <Helmet>
        <title>Services - SM Cloud</title>
        <meta property="og:title" content="Services - SM Cloud" />
      </Helmet>
      <Navbar></Navbar>
      <ServicesHeader rootClassName="services-header-root-class-name"></ServicesHeader>
      <ServicesFeatures
        heading={
          <Fragment>
            <h2 className="services-text thq-heading-2">SEO</h2>
          </Fragment>
        }
        rootClassName="services-features-root-class-name"
      ></ServicesFeatures>
      <Gallery7 rootClassName="gallery7-root-class-name"></Gallery7>
      <Team14 rootClassName="team14-root-class-name"></Team14>
      <Contact3 rootClassName="contact3-root-class-name"></Contact3>
      <Footer rootClassName="footer-root-class-name10"></Footer>
    </div>
  );
};

export default Services;
