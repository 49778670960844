import React, { useState } from 'react'

import PropTypes from 'prop-types'

import './home-serv-marketing.css'

const HomeServMarketing = (props) => {
  const [activeTab, setActiveTab] = useState(0)
  return (
    <div className={`thq-section-padding ${props.rootClassName} `}>
      <div className="home-serv-marketing-container1 thq-section-max-width">
        <div className="home-serv-marketing-tabs-menu">
          <div
            onClick={() => setActiveTab(0)}
            className="home-serv-marketing-tab-horizontal"
          >
            <div className="home-serv-marketing-divider-container">
              {activeTab === 0 && (
                <div className="home-serv-marketing-container2"></div>
              )}
            </div>
            <div className="home-serv-marketing-content">
              <h2 className="thq-heading-2">{props.feature1Title}</h2>
              <span className="thq-body-small">
                {props.feature1Description}
              </span>
            </div>
          </div>
          <div
            onClick={() => setActiveTab(1)}
            className="home-serv-marketing-tab-horizontal1"
          >
            <div className="home-serv-marketing-divider-container1">
              {activeTab === 1 && (
                <div className="home-serv-marketing-container3"></div>
              )}
            </div>
            <div className="home-serv-marketing-content1">
              <h2 className="thq-heading-2">{props.feature2Title}</h2>
              <span className="thq-body-small">
                {props.feature2Description}
              </span>
            </div>
          </div>
          <div
            onClick={() => setActiveTab(2)}
            className="home-serv-marketing-tab-horizontal2"
          >
            <div className="home-serv-marketing-divider-container2">
              {activeTab === 2 && (
                <div className="home-serv-marketing-container4"></div>
              )}
            </div>
            <div className="home-serv-marketing-content2">
              <h2 className="thq-heading-2">{props.feature3Title}</h2>
              <span className="thq-body-small">
                {props.feature3Description}
              </span>
            </div>
          </div>
        </div>
        <div className="home-serv-marketing-image-container">
          {activeTab === 0 && (
            <img
              alt={props.feature1ImgAlt}
              src={props.feature1ImgSrc}
              className="home-serv-marketing-image thq-img-ratio-16-9"
            />
          )}
          {activeTab === 1 && (
            <img
              alt={props.feature2ImgAlt}
              src={props.feature2ImgSrc}
              className="home-serv-marketing-image1 thq-img-ratio-16-9"
            />
          )}
          {activeTab === 2 && (
            <img
              alt={props.feature3ImgAlt}
              src={props.feature3ImgSrc}
              className="home-serv-marketing-image2 thq-img-ratio-16-9"
            />
          )}
        </div>
      </div>
    </div>
  )
}

HomeServMarketing.defaultProps = {
  feature1Title: 'Search Engine Optimization (SEO)',
  feature3Description:
    'Run targeted ads to reach potential customers and drive conversions.',
  feature2ImgAlt: 'Social Media Icon',
  feature1ImgAlt: 'SEO Icon',
  feature3Title: 'Pay-Per-Click Advertising (PPC)',
  rootClassName: '',
  feature1Description:
    "Improve your website's visibility on search engines and drive organic traffic.",
  feature1ImgSrc:
    'https://images.unsplash.com/photo-1533750349088-cd871a92f312?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMTA1OTc2Mnw&ixlib=rb-4.0.3&q=80&w=1500',
  feature2ImgSrc:
    'https://images.unsplash.com/photo-1521737711867-e3b97375f902?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMTA1OTc2NHw&ixlib=rb-4.0.3&q=80&w=1500',
  feature2Title: 'Social Media Marketing',
  feature3ImgSrc:
    'https://images.unsplash.com/photo-1516321497487-e288fb19713f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMTA1OTc2M3w&ixlib=rb-4.0.3&q=80&w=1500',
  feature3ImgAlt: 'PPC Icon',
  feature2Description:
    'Engage with your audience on popular social media platforms to increase brand awareness.',
}

HomeServMarketing.propTypes = {
  feature1Title: PropTypes.string,
  feature3Description: PropTypes.string,
  feature2ImgAlt: PropTypes.string,
  feature1ImgAlt: PropTypes.string,
  feature3Title: PropTypes.string,
  rootClassName: PropTypes.string,
  feature1Description: PropTypes.string,
  feature1ImgSrc: PropTypes.string,
  feature2ImgSrc: PropTypes.string,
  feature2Title: PropTypes.string,
  feature3ImgSrc: PropTypes.string,
  feature3ImgAlt: PropTypes.string,
  feature2Description: PropTypes.string,
}

export default HomeServMarketing
