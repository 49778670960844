import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './feature-card.css'

const FeatureCard = (props) => {
  return (
    <div className="feature-card-container">
      <div className="feature-card-container1">
        <svg viewBox="0 0 1024 1024" className="feature-card-icon">
          <path d="M896 448h16c26.4 0 48-21.6 48-48v-160c0-26.4-21.6-48-48-48h-16v-192h-128v192h-16c-26.4 0-48 21.6-48 48v160c0 26.4 21.6 48 48 48h16v576h128v-576zM768 256h128v128h-128v-128zM592 832c26.4 0 48-21.6 48-48v-160c0-26.4-21.6-48-48-48h-16v-576h-128v576h-16c-26.4 0-48 21.6-48 48v160c0 26.4 21.6 48 48 48h16v192h128v-192h16zM448 640h128v128h-128v-128zM272 448c26.4 0 48-21.6 48-48v-160c0-26.4-21.6-48-48-48h-16v-192h-128v192h-16c-26.4 0-48 21.6-48 48v160c0 26.4 21.6 48 48 48h16v576h128v-576h16zM128 256h128v128h-128v-128z"></path>
        </svg>
      </div>
      <div className="feature-card-container2">
        <h1>
          {props.heading ?? (
            <Fragment>
              <h1 className="feature-card-text2">Expertise and Experience:</h1>
            </Fragment>
          )}
        </h1>
        <span>
          {props.text ?? (
            <Fragment>
              <span className="feature-card-text3">
                With years of experience in the industry, our professionals
                bring deep knowledge and a proven track record to every project.
              </span>
            </Fragment>
          )}
        </span>
      </div>
    </div>
  )
}

FeatureCard.defaultProps = {
  heading: undefined,
  text: undefined,
}

FeatureCard.propTypes = {
  heading: PropTypes.element,
  text: PropTypes.element,
}

export default FeatureCard
