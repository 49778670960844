import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './pricing-header.css'

const PricingHeader = (props) => {
  return (
    <div
      className={`pricing-header-header30 thq-section-padding ${props.rootClassName} `}
    >
      <img
        alt={props.image1Alt}
        src={props.image1Src}
        className="pricing-header-image"
      />
      <div className="pricing-header-container"></div>
      <div className="pricing-header-max-width thq-section-max-width">
        <div className="pricing-header-content">
          <h1 className="pricing-header-text thq-heading-1">
            {props.heading1}
          </h1>
          <p className="pricing-header-text1 thq-body-large">
            {props.content1}
          </p>
          <div className="pricing-header-actions">
            <Link
              to="/contact-us"
              className="pricing-header-button thq-button-filled"
            >
              <span className="pricing-header-text2 thq-body-small">
                {props.action1}
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

PricingHeader.defaultProps = {
  heading1: 'Pricing Plans',
  image1Src:
    'https://images.unsplash.com/photo-1667984390538-3dea7a3fe33d?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDE3fHxjbG91ZCUyMGNvbXB1dGV8ZW58MHx8fHwxNzIyNTc4Njk4fDA&ixlib=rb-4.0.3&w=1500',
  image1Alt: 'Digital Marketing Services',
  content1:
    'Choose from our wide variety of plans or reach us for a custom plan to carter your business needs',
  rootClassName: '',
  action1: 'Custom Plans',
}

PricingHeader.propTypes = {
  heading1: PropTypes.string,
  image1Src: PropTypes.string,
  image1Alt: PropTypes.string,
  content1: PropTypes.string,
  rootClassName: PropTypes.string,
  action1: PropTypes.string,
}

export default PricingHeader
