import React from "react";

import PropTypes from "prop-types";

import "./contact-form.css";

const ContactForm = (props) => {
  return (
    <div className="contact-form-contact9 thq-section-padding">
      <div className="thq-flex-row thq-section-max-width contact-form-max-width">
        <img
          alt={props.imageAlt}
          src={props.imageSrc}
          className="contact-form-image1 thq-img-ratio-4-3"
        />
        <div className="contact-form-content thq-flex-column">
          <div className="contact-form-section-title thq-card">
            <span className="thq-body-small">{props.content2}</span>
            <div className="contact-form-content1">
              <h2 className="thq-heading-2">{props.heading1}</h2>
              <span className="thq-body-small">{props.content1}</span>
            </div>
          </div>
          <form className="thq-card">
            <div className="contact-form-input">
              <label htmlFor="contact-form-3-name" className="thq-body-small">
                Name
              </label>
              <input
                type="text"
                id="contact-form-3-name"
                placeholder="Name"
                className="thq-input"
              />
            </div>
            <div className="contact-form-input1">
              <label htmlFor="contact-form-3-email" className="thq-body-small">
                Email
              </label>
              <input
                type="email"
                id="contact-form-3-email"
                required="true"
                placeholder="Email"
                className="thq-input"
              />
            </div>
            <div className="contact-form-container">
              <label
                htmlFor="contact-form-3-message"
                className="thq-body-small"
              >
                Message
              </label>
              <textarea
                id="contact-form-3-message"
                rows="3"
                placeholder="Enter your message"
                className="thq-input"
              ></textarea>
            </div>
            <div className="contact-form-checkbox">
              <input
                type="checkbox"
                id="contact-form-3-check"
                checked="true"
                required="true"
                className="thq-checkbox"
              />
              <label
                htmlFor="contact-form-3-check"
                className="contact-form-text6 thq-body-small"
              >
                I accept the Terms
              </label>
            </div>
            <button
              type="submit"
              className="contact-form-button thq-button-filled"
            >
              <span className="thq-body-small">{props.action}</span>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

ContactForm.defaultProps = {
  content1:
    "For inquiries and appointments, please fill the form below or use email or phone during our business hours",
  heading1: "Contact us",
  imageSrc:
    "https://images.unsplash.com/photo-1634733988596-093e2a324c2f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMTA2MDUxOXw&ixlib=rb-4.0.3&q=80&w=1080",
  imageAlt: "Image1",
  content2: "Get in touch with us",
  action: "Submit",
};

ContactForm.propTypes = {
  content1: PropTypes.string,
  heading1: PropTypes.string,
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
  content2: PropTypes.string,
  action: PropTypes.string,
};

export default ContactForm;
