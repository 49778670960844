import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './about-header.css'

const AboutHeader = (props) => {
  return (
    <div className="about-header-about-header thq-section-padding">
      <img
        alt={props.imageAlt}
        src={props.imageSrc}
        className="about-header-image"
      />
      <div className="about-header-container"></div>
      <div className="about-header-max-width thq-section-max-width">
        <div className="about-header-content">
          <h1 className="about-header-text">
            {props.text ?? (
              <Fragment>
                <h1 className="about-header-text3 thq-heading-1">
                  SEO Services
                </h1>
              </Fragment>
            )}
          </h1>
          <p className="about-header-text1">
            {props.text1 ?? (
              <Fragment>
                <p className="about-header-text2 thq-body-large">
                  Choose from our wide variety of plans or reach us for a custom
                  plan to carter your business needs
                </p>
              </Fragment>
            )}
          </p>
          <div className="about-header-actions"></div>
        </div>
      </div>
    </div>
  )
}

AboutHeader.defaultProps = {
  imageAlt: 'Digital Marketing Services',
  imageSrc: '/about-us-1500w.webp',
  text1: undefined,
  text: undefined,
}

AboutHeader.propTypes = {
  imageAlt: PropTypes.string,
  imageSrc: PropTypes.string,
  text1: PropTypes.element,
  text: PropTypes.element,
}

export default AboutHeader
