import React from 'react'

import PropTypes from 'prop-types'

import './gallery7.css'

const Gallery7 = (props) => {
  return (
    <div
      className={`gallery7-gallery3 thq-section-padding ${props.rootClassName} `}
    >
      <div className="gallery7-max-width">
        <div className="gallery7-section-title">
          <h2 className="gallery7-text thq-heading-2">{props.heading1}</h2>
          <span className="gallery7-text1 thq-body-large">
            {props.content1}
          </span>
        </div>
        <div className="gallery7-container">
          <div className="gallery7-content">
            <div className="gallery7-container1">
              <img
                alt={props.image1Alt}
                src={props.image1Src}
                className="gallery7-image1 thq-img-ratio-1-1"
              />
              <img
                alt={props.image2Alt}
                src={props.image2Src}
                className="gallery7-image2 thq-img-ratio-1-1"
              />
            </div>
            <div className="gallery7-container2">
              <img
                alt={props.image3Alt}
                src={props.image3Src}
                className="gallery7-image3 thq-img-ratio-4-3"
              />
              <img
                alt={props.image4Alt}
                src={props.image4Src}
                className="gallery7-image4 thq-img-ratio-1-1"
              />
              <img
                alt={props.image5Alt}
                src={props.image5Src}
                className="gallery7-image5 thq-img-ratio-4-3"
              />
            </div>
            <div className="gallery7-container3">
              <img
                alt={props.image6Alt}
                src={props.image6Src}
                className="gallery7-image6 thq-img-ratio-1-1"
              />
              <img
                alt={props.image7Alt}
                src={props.image7Src}
                className="gallery7-image7 thq-img-ratio-1-1"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Gallery7.defaultProps = {
  heading1: 'Our Services',
  image7Alt: 'Pricing Plans',
  image6Alt: 'Customized Marketing Plans',
  image2Src:
    'https://images.unsplash.com/photo-1532622785990-d2c36a76f5a6?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMTA2MDY4M3w&ixlib=rb-4.0.3&q=80&w=1080',
  image4Src:
    'https://images.unsplash.com/photo-1582125169651-2cc534568f4f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMTA2MDY4Mnw&ixlib=rb-4.0.3&q=80&w=1080',
  image1Alt: 'SEO Services',
  image7Src:
    'https://images.unsplash.com/photo-1602661287394-ccf02e1a0893?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMTA2MDY4Mnw&ixlib=rb-4.0.3&q=80&w=1080',
  image3Src:
    'https://images.unsplash.com/photo-1563762270340-3f5fde3243cd?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMTA2MDY4MXw&ixlib=rb-4.0.3&q=80&w=1080',
  rootClassName: '',
  image6Src:
    'https://images.unsplash.com/photo-1434626881859-194d67b2b86f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMTA2MDY4M3w&ixlib=rb-4.0.3&q=80&w=1080',
  image5Alt: 'Client Testimonials',
  image5Src:
    'https://images.unsplash.com/photo-1494887205043-c5f291293cf6?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMTA2MDY4M3w&ixlib=rb-4.0.3&q=80&w=1080',
  image2Alt: 'Social Media Marketing',
  image3Alt: 'PPC Advertising',
  content1:
    'Check out our range of digital marketing services to boost your online presence.',
  image1Src:
    'https://images.unsplash.com/photo-1607703703520-bb638e84caf2?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMTA2MDY4NHw&ixlib=rb-4.0.3&q=80&w=1080',
  image4Alt: 'Website Design',
}

Gallery7.propTypes = {
  heading1: PropTypes.string,
  image7Alt: PropTypes.string,
  image6Alt: PropTypes.string,
  image2Src: PropTypes.string,
  image4Src: PropTypes.string,
  image1Alt: PropTypes.string,
  image7Src: PropTypes.string,
  image3Src: PropTypes.string,
  rootClassName: PropTypes.string,
  image6Src: PropTypes.string,
  image5Alt: PropTypes.string,
  image5Src: PropTypes.string,
  image2Alt: PropTypes.string,
  image3Alt: PropTypes.string,
  content1: PropTypes.string,
  image1Src: PropTypes.string,
  image4Alt: PropTypes.string,
}

export default Gallery7
