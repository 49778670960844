import React from "react";
import ReactDOM from "react-dom";
import "./style.css";
import NotFound from "./views/not-found";
import Pricing from "./views/pricing";
import Refund from "./views/refund";
import CloudServices from "./views/cloud-services";
import Services from "./views/services";
import About from "./views/about";
import WebDevelopment from "./views/web-development";
import Home from "./views/home";
import DigitalMarketing from "./views/digital-marketing";
import ContactUs from "./views/contact-us";
import Legal from "./views/legal";
import { BrowserRouter, Routes, Route } from "react-router-dom";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<NotFound />} exact path="/not-found" />
        <Route element={<Pricing />} exact path="/pricing" />
        <Route element={<Refund />} exact path="/refund" />
        <Route element={<CloudServices />} exact path="/cloud-services" />
        <Route element={<Services />} exact path="/services" />
        <Route element={<About />} exact path="/about" />
        <Route element={<WebDevelopment />} exact path="/web-development" />
        <Route element={<Home />} exact path="/" />
        <Route element={<DigitalMarketing />} exact path="/digital-marketing" />
        <Route element={<ContactUs />} exact path="/contact-us" />
        <Route element={<Legal />} exact path="/legal" />
        <Route element={<NotFound />} path="**" />
      </Routes>
    </BrowserRouter>
  );
};

ReactDOM.render(<App />, document.getElementById("app"));
