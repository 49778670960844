import React from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import "./navbar.css";

const Navbar = (props) => {
  return (
    <header className={`navbar-container ${props.rootClassName} `}>
      <header data-thq="thq-navbar" className="navbar-navbar-interactive">
        <Link to="/">
          <img
            alt={props.imageAlt1}
            src={props.imageSrc2}
            className="navbar-image"
          />
        </Link>

        <div data-thq="thq-navbar-nav" className="navbar-desktop-menu">
          <nav className="navbar-navbar">
            {/* div for logo */}
            <div className="navbar-logo-div">
              <Link to="/" className="navbar-navlink04">
                <img
                  alt={props.logoImage}
                  src={props.imageSrc}
                  className="navbar-logo-nav"
                />
              </Link>
            </div>
            {/* Div for nav items */}
            <div className="navbar-items-center">
              {/* Div for Services item / dropdown */}
              <div data-thq="thq-dropdown" className="navbar-services-nav">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="navbar-dropdown-toggle"
                >
                  <Link
                    to="/services"
                    className="navbar-navlink thq-body-small thq-link"
                  >
                    Services
                  </Link>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="navbar-dropdown-arrow"
                  >
                    <svg viewBox="0 0 1024 1024" className="navbar-icon">
                      <path d="M426 726v-428l214 214z"></path>
                    </svg>
                  </div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="navbar-dropdown-list"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="navbar-dropdown list-item"
                  >
                    <Link to="/cloud-services">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="navbar-dropdown-toggle1"
                      >
                        <span className="navbar-text">Cloud Services</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="navbar-dropdown1 list-item"
                  >
                    <Link to="/digital-marketing">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="navbar-dropdown-toggle2"
                      >
                        <span className="navbar-text01">Digital Marketing</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="navbar-dropdown2 list-item"
                  >
                    <Link to="/web-development">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="navbar-dropdown-toggle3"
                      >
                        <span className="navbar-text02">Web Development</span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <Link
                to="/cloud-services"
                className="navbar-cloud-nav thq-body-small thq-link"
              >
                Cloud Services
              </Link>
              <Link
                to="/digital-marketing"
                className="navbar-digmkt-nav thq-body-small thq-link"
              >
                Digital Marketing
              </Link>
              <Link
                to="/web-development"
                className="navbar-webdev-nav thq-body-small thq-link"
              >
                Web Development
              </Link>
              <Link
                to="/pricing"
                className="navbar-pricing-nav thq-body-small thq-link"
              >
                {props.pricingLink}
              </Link>
              <Link
                to="/about"
                className="navbar-about-nav thq-body-small thq-link"
              >
                {props.aboutLink}
              </Link>
            </div>
            <div className="navbar-contact-div">
              <Link
                to="/contact-us"
                className="navbar-contact-nav thq-body-small thq-link"
              >
                {props.contactNav}
              </Link>
            </div>
          </nav>
        </div>
        <div data-thq="thq-burger-menu" className="navbar-collapse-icon">
          <svg viewBox="0 0 1024 1024" className="navbar-icon2">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div data-thq="thq-mobile-menu" className="navbar-mobile-menu">
          <div className="navbar-nav">
            <div className="navbar-top">
              <div data-thq="thq-close-menu" className="navbar-close-menu">
                <svg viewBox="0 0 1024 1024" className="navbar-close-icon">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <div className="navbar-logo-container">
              <Link to="/" className="navbar-navlink05">
                <img
                  alt={props.imageAlt}
                  src={props.imageSrc1}
                  className="navbar-sm-logo"
                />
              </Link>
            </div>
            <nav className="navbar-links">
              <div
                data-thq="thq-dropdown"
                className="navbar-services-drop list-item"
              >
                <Link to="/services">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="navbar-dropdown-toggle4"
                  >
                    <span className="navbar-text03">
                      <span className="navbar-text04">Services</span>
                      <br></br>
                    </span>
                    <div
                      data-thq="thq-dropdown-arrow"
                      className="navbar-dropdown-arrow1"
                    >
                      <svg viewBox="0 0 1024 1024" className="navbar-icon5">
                        <path d="M426 726v-428l214 214z"></path>
                      </svg>
                    </div>
                  </div>
                </Link>
                <ul
                  data-thq="thq-dropdown-list"
                  className="navbar-dropdown-list1"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="navbar-dropdown3 list-item"
                  >
                    <Link to="/cloud-services">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="navbar-dropdown-toggle5"
                      >
                        <span className="navbar-text06">Cloud Services</span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="navbar-dropdown5 list-item"
                  >
                    <Link to="/digital-marketing">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="navbar-dropdown-toggle7"
                      >
                        <span className="navbar-text08">
                          <span className="navbar-text09">
                            Digital Marketing
                          </span>
                          <br></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="navbar-dropdown6 list-item"
                  >
                    <Link to="/web-development">
                      <div
                        data-thq="thq-dropdown-toggle"
                        className="navbar-dropdown-toggle8"
                      >
                        <span className="navbar-text11">
                          <span className="navbar-text12">Web Development</span>
                          <br></br>
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <Link
                to="/pricing"
                className="navbar-pricing-nav1 thq-body-small thq-link"
              >
                {props.pricingLink}
              </Link>
              <Link
                to="/about"
                className="navbar-about-nav1 thq-body-small thq-link"
              >
                {props.aboutLink}
              </Link>
              <Link
                to="/contact-us"
                className="navbar-contact-nav1 thq-body-small thq-link"
              >
                {props.contactNav}
              </Link>
            </nav>
          </div>
        </div>
      </header>
    </header>
  );
};

Navbar.defaultProps = {
  logoAlt: "sm Logo",
  imageAlt: "image",
  imageSrc: "/sm%20cloud%20logo%20name.png",
  smLogo: "4e2b4d26-0614-4638-a6f9-3d4b25c813f5",
  imageSrc2: "/sm%20cloud%20logo%20name%20line.png",
  rootClassName: "",
  aboutLink: "About Us",
  contactNav: "Contact Us",
  logoImage: "sm Logo",
  imageSrc1: "/sm%20cloud%20logo%20name%20line.png",
  pricingLink: "Pricing",
  imageAlt1: "image",
};

Navbar.propTypes = {
  logoAlt: PropTypes.string,
  imageAlt: PropTypes.string,
  imageSrc: PropTypes.string,
  smLogo: PropTypes.string,
  imageSrc2: PropTypes.string,
  rootClassName: PropTypes.string,
  aboutLink: PropTypes.string,
  contactNav: PropTypes.string,
  logoImage: PropTypes.string,
  imageSrc1: PropTypes.string,
  pricingLink: PropTypes.string,
  imageAlt1: PropTypes.string,
};

export default Navbar;
