import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './cloud-header.css'

const CloudHeader = (props) => {
  return (
    <div className="cloud-header-cloud-header thq-section-padding">
      <img
        alt={props.imageAlt}
        src={props.imageSrc}
        className="cloud-header-image"
      />
      <div className="cloud-header-container"></div>
      <div className="cloud-header-max-width thq-section-max-width">
        <div className="cloud-header-content">
          <h1 className="cloud-header-text">
            {props.text ?? (
              <Fragment>
                <h1 className="cloud-header-text3 thq-heading-1">
                  Cloud Services
                </h1>
              </Fragment>
            )}
          </h1>
          <p className="cloud-header-text1">
            {props.text1 ?? (
              <Fragment>
                <p className="cloud-header-text2 thq-body-large">
                  Elevate Your Business with Cutting-Edge Cloud Solutions
                </p>
              </Fragment>
            )}
          </p>
          <div className="cloud-header-actions"></div>
        </div>
      </div>
    </div>
  )
}

CloudHeader.defaultProps = {
  text1: undefined,
  imageSrc: '/cloud%20compute-1500w.webp',
  imageAlt: 'Digital Marketing Services',
  text: undefined,
}

CloudHeader.propTypes = {
  text1: PropTypes.element,
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
  text: PropTypes.element,
}

export default CloudHeader
