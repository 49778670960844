import React, { Fragment } from "react";

import PropTypes from "prop-types";

import "./cloud-content.css";

const CloudContent = (props) => {
  return (
    <div className="cloud-content-cloud-content thq-section-padding">
      <div className="cloud-content-max-width thq-section-max-width">
        <div className="cloud-content-content thq-flex-column">
          <ul className="cloud-content-ul thq-flex-column">
            <li className="thq-flex-column list-item">
              <p>
                {props.content1 ?? (
                  <Fragment>
                    <p className="cloud-content-content11 thq-body-small">
                      <span className="cloud-content-text009">
                        Welcome to SM Cloud, your trusted partner for cloud
                        solutions. Whether you are looking to migrate, manage,
                        or optimize your cloud infrastructure, we provide
                        comprehensive services across Google Cloud Platform
                        (GCP), Microsoft Azure, and Amazon Web Services (AWS).
                        Let us help you unlock the full potential of the cloud
                        to drive innovation, efficiency, and growth.
                      </span>
                      <br></br>
                      <br></br>
                      <span className="cloud-content-text012">
                        What is Cloud and why you need it?
                      </span>
                      <br></br>
                      <br></br>
                      <span>
                        The cloud is a network of remote servers hosted on the
                        internet to store, manage, and process data. Unlike
                        traditional on-premise infrastructure, cloud computing
                        allows for scalable resources and services that can be
                        accessed on-demand, providing flexibility and efficiency
                        for businesses of all sizes.
                      </span>
                      <br></br>
                      <span className="cloud-content-text018">
                        Cloud computing offers numerous benefits including cost
                        savings, scalability, flexibility, disaster recovery,
                        and remote access. By leveraging cloud services,
                        businesses can reduce IT costs, scale resources
                        according to demand, improve collaboration, and ensure
                        data security and compliance.
                      </span>
                      <br></br>
                      <br></br>
                      <span className="cloud-content-text022">Why Us?</span>
                      <br></br>
                      <br></br>
                      <span className="cloud-content-text025">
                        At SM Cloud, we understand that every business is
                        unique. Our tailored approach ensures that your digital
                        marketing strategy is aligned with your specific
                        business objectives. Here’s what sets us apart:
                      </span>
                      <br></br>
                      <span className="cloud-content-text028">
                        ➤ 
                        <span
                          dangerouslySetInnerHTML={{
                            __html: " ",
                          }}
                        />
                      </span>
                      <span className="cloud-content-text029">
                        Expertise Across Major Cloud Platforms
                      </span>
                      <span className="cloud-content-text030">: </span>
                      <span>
                        Our certified cloud architects and engineers have
                        extensive experience with GCP, Azure, and AWS, ensuring
                        we can meet your unique business needs.
                      </span>
                      <br className="cloud-content-text032"></br>
                      <span className="cloud-content-text033">➤  </span>
                      <span className="cloud-content-text034">
                        Customized Solutions:
                        <span
                          dangerouslySetInnerHTML={{
                            __html: " ",
                          }}
                        />
                      </span>
                      <span>
                        We tailor our cloud services to align with your business
                        goals, providing personalized solutions that drive
                        success.
                      </span>
                      <br className="cloud-content-text036"></br>
                      <span className="cloud-content-text037">➤  </span>
                      <span className="cloud-content-text038">
                        Proven Track Record:
                        <span
                          dangerouslySetInnerHTML={{
                            __html: " ",
                          }}
                        />
                      </span>
                      <span>
                        We have a history of successful cloud projects across
                        various industries, demonstrating our ability to deliver
                        high-quality results.
                      </span>
                      <br></br>
                      <span className="cloud-content-text041">
                        ➤  End-to-End Support:
                        <span
                          dangerouslySetInnerHTML={{
                            __html: " ",
                          }}
                        />
                      </span>
                      <span>
                        From initial consultation and planning to deployment and
                        ongoing support, we are with you every step of the way.
                      </span>
                      <br></br>
                      <br></br>
                      <span>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: " ",
                          }}
                        />
                      </span>
                      <span className="cloud-content-text046">
                        What Cloud Platforms do we work on?
                      </span>
                      <br></br>
                      <br></br>
                      <span className="cloud-content-text049">
                        Google Cloud Platform (GCP)
                      </span>
                      <br></br>
                      <span>
                        Harness the Power of Google’s Cloud for Your Business
                      </span>
                      <br></br>
                      <span>   </span>
                      <span className="cloud-content-text054">•  </span>
                      <span className="cloud-content-text055">
                        Cloud Migration:
                      </span>
                      <span>
                        {" "}
                        Seamlessly move your applications, data, and workloads
                        to GCP with minimal downtime and disruption.
                      </span>
                      <br></br>
                      <span className="cloud-content-text058">   •  </span>
                      <span className="cloud-content-text059">
                        Infrastructure Modernization:
                      </span>
                      <span>
                        {" "}
                        Modernize your IT infrastructure with GCP’s robust and
                        scalable cloud services, including Compute Engine,
                        Kubernetes Engine, and Cloud Storage.
                      </span>
                      <br></br>
                      <span className="cloud-content-text062">   •  </span>
                      <span className="cloud-content-text063">
                        Data Analytics &amp; AI:
                      </span>
                      <span>
                        {" "}
                        Leverage GCP’s advanced data analytics and AI tools to
                        gain deeper insights and drive smarter decision-making.
                      </span>
                      <br></br>
                      <span className="cloud-content-text066">   •  </span>
                      <span className="cloud-content-text067">
                        Security &amp; Compliance:
                        <span
                          dangerouslySetInnerHTML={{
                            __html: " ",
                          }}
                        />
                      </span>
                      <span>
                        Ensure your cloud environment is secure and compliant
                        with industry standards using GCP’s comprehensive
                        security features and tools.
                      </span>
                      <br></br>
                      <br></br>
                      <span className="cloud-content-text071">
                        Microsoft Azure
                      </span>
                      <br></br>
                      <span>
                        Empower Your Enterprise with Microsoft’s Azure Cloud
                        Solutions
                      </span>
                      <br></br>
                      <span className="cloud-content-text075">   •  </span>
                      <span className="cloud-content-text076">
                        Hybrid Cloud Solutions:
                      </span>
                      <span>
                        {" "}
                        Integrate your on-premises and cloud environments
                        seamlessly with Azure’s hybrid cloud capabilities.
                      </span>
                      <br></br>
                      <span className="cloud-content-text079">   •  </span>
                      <span className="cloud-content-text080">
                        DevOps &amp; Automation:
                      </span>
                      <span>
                        {" "}
                        Accelerate your development and operations with Azure’s
                        DevOps tools, enabling continuous integration and
                        delivery.
                      </span>
                      <br></br>
                      <span className="cloud-content-text083">   •  </span>
                      <span className="cloud-content-text084">
                        AI &amp; Machine Learning:
                      </span>
                      <span>
                        {" "}
                        Innovate with Azure’s AI and machine learning services
                        to build intelligent applications and solutions.
                      </span>
                      <br></br>
                      <span className="cloud-content-text087">   •  </span>
                      <span className="cloud-content-text088">
                        Business Continuity &amp; Disaster Recovery:
                      </span>
                      <span>
                        {" "}
                        Protect your critical applications and data with Azure’s
                        reliable backup and disaster recovery solutions.
                      </span>
                      <br></br>
                      <br></br>
                      <span className="cloud-content-text092">
                        Amazon Web Services (AWS)
                      </span>
                      <br className="cloud-content-text093"></br>
                      <span>
                        Build, Innovate, and Scale with AWS Cloud Services
                      </span>
                      <br></br>
                      <span className="cloud-content-text096">   •  </span>
                      <span className="cloud-content-text097">
                        Cloud Infrastructure:
                      </span>
                      <span>
                        {" "}
                        Deploy and manage scalable and flexible infrastructure
                        with AWS’s wide range of services, including EC2, S3,
                        and RDS.
                      </span>
                      <br></br>
                      <span className="cloud-content-text100">   •  </span>
                      <span className="cloud-content-text101">
                        Serverless Computing:
                      </span>
                      <span>
                        {" "}
                        Simplify application development and reduce operational
                        overhead with AWS’s serverless computing services like
                        Lambda and Fargate.
                      </span>
                      <br></br>
                      <span className="cloud-content-text104">   •  </span>
                      <span className="cloud-content-text105">
                        Big Data &amp; Analytics:
                      </span>
                      <span>
                        {" "}
                        Transform your data into actionable insights with AWS’s
                        powerful big data and analytics services, such as
                        Redshift and Kinesis.
                      </span>
                      <br className="cloud-content-text107"></br>
                      <span className="cloud-content-text108">   •  </span>
                      <span className="cloud-content-text109">
                        Cost Management:
                        <span
                          dangerouslySetInnerHTML={{
                            __html: " ",
                          }}
                        />
                      </span>
                      <span>
                        Optimize your cloud spending with AWS’s cost management
                        tools, ensuring you get the most value from your cloud
                        investment.
                      </span>
                      <br></br>
                    </p>
                  </Fragment>
                )}
              </p>
            </li>
            <li className="list-item">
              <h3>
                {props.heading2 ?? (
                  <Fragment>
                    <h3 className="cloud-content-text123 thq-heading-3">
                      Cloud based Services at SM Cloud include:
                    </h3>
                  </Fragment>
                )}
              </h3>
              <h3 className="cloud-content-heading201">
                {props.heading21 ?? (
                  <Fragment>
                    <h3 className="cloud-content-text127 thq-heading-3">
                      ➤ Consultation
                    </h3>
                  </Fragment>
                )}
              </h3>
              <h3 className="cloud-content-heading202">
                {props.heading22 ?? (
                  <Fragment>
                    <h3 className="cloud-content-text124 thq-heading-3">
                      ➤ Onboarding
                    </h3>
                  </Fragment>
                )}
              </h3>
              <h3 className="cloud-content-heading203">
                {props.heading23 ?? (
                  <Fragment>
                    <h3 className="cloud-content-text001 thq-heading-3">
                      ➤ Security
                    </h3>
                  </Fragment>
                )}
              </h3>
              <h3 className="cloud-content-heading204">
                {props.heading24 ?? (
                  <Fragment>
                    <h3 className="cloud-content-text118 thq-heading-3">
                      ➤ Cost Optimization
                    </h3>
                  </Fragment>
                )}
              </h3>
              <h3 className="cloud-content-heading205">
                {props.heading25 ?? (
                  <Fragment>
                    <h3 className="cloud-content-text002 thq-heading-3">
                      ➤ Architecture &amp; Migration
                    </h3>
                  </Fragment>
                )}
              </h3>
              <h3 className="cloud-content-heading206">
                {props.heading26 ?? (
                  <Fragment>
                    <h3 className="cloud-content-text116 thq-heading-3">
                      ➤ Hybrid Cloud Solutions
                    </h3>
                  </Fragment>
                )}
              </h3>
              <h3 className="cloud-content-heading207">
                {props.heading27 ?? (
                  <Fragment>
                    <h3 className="cloud-content-text007 thq-heading-3">
                      ➤ Application Modrenization
                    </h3>
                  </Fragment>
                )}
              </h3>
              <h3 className="cloud-content-heading208">
                {props.heading28 ?? (
                  <Fragment>
                    <h3 className="cloud-content-text thq-heading-3">
                      ➤ Training &amp; Certification
                    </h3>
                  </Fragment>
                )}
              </h3>
            </li>
            <li className="list-item">
              <h3 className="cloud-content-heading209">
                {props.heading29 ?? (
                  <Fragment>
                    <h3 className="cloud-content-text112 thq-heading-3">
                      Consultation
                    </h3>
                  </Fragment>
                )}
              </h3>
              <p className="cloud-content-content2">
                {props.content2 ?? (
                  <Fragment>
                    <p className="cloud-content-text005 thq-body-small">
                      Unlock the full potential of your business with our expert
                      cloud consultation services. Our team of certified
                      professionals will work closely with you to understand
                      your unique needs and challenges, providing tailored
                      strategies that leverage the best of GCP, AWS, and Azure.
                      We offer comprehensive assessments and actionable roadmaps
                      to ensure your cloud journey is aligned with your business
                      objectives, maximizing efficiency and innovation.
                    </p>
                  </Fragment>
                )}
              </p>
            </li>
            <li className="list-item">
              <h3 className="cloud-content-heading3">
                {props.heading3 ?? (
                  <Fragment>
                    <h3 className="cloud-content-text120 thq-heading-3">
                      Onboarding
                    </h3>
                  </Fragment>
                )}
              </h3>
              <p className="cloud-content-content3">
                {props.content3 ?? (
                  <Fragment>
                    <p className="cloud-content-text113 thq-body-small">
                      Transition seamlessly to the cloud with our structured and
                      efficient cloud onboarding services. We ensure a smooth
                      migration process to GCP, AWS, or Azure, minimizing
                      downtime and disruption to your operations. Our
                      specialists will guide you through every step, from
                      initial setup and configuration to data migration and
                      application deployment, ensuring your cloud environment is
                      optimized for performance and scalability.
                    </p>
                  </Fragment>
                )}
              </p>
            </li>
            <li className="list-item">
              <h3 className="cloud-content-heading4">
                {props.heading4 ?? (
                  <Fragment>
                    <h3 className="cloud-content-text117 thq-heading-3">
                      Security
                    </h3>
                  </Fragment>
                )}
              </h3>
              <p className="cloud-content-content4">
                {props.content4 ?? (
                  <Fragment>
                    <p className="cloud-content-text004 thq-body-small">
                      Protect your digital assets with our robust cloud security
                      solutions. We offer comprehensive security assessments,
                      implementation of advanced security measures, and
                      continuous monitoring to safeguard your data and
                      applications on GCP, AWS, and Azure. Our services include
                      identity and access management, threat detection and
                      response, and compliance management, ensuring your cloud
                      infrastructure meets the highest standards of security.
                    </p>
                  </Fragment>
                )}
              </p>
            </li>
            <li className="list-item">
              <h3 className="cloud-content-heading41">
                {props.heading41 ?? (
                  <Fragment>
                    <h3 className="cloud-content-text125 thq-heading-3">
                      Cost Optimization
                    </h3>
                  </Fragment>
                )}
              </h3>
              <p className="cloud-content-content41">
                {props.content41 ?? (
                  <Fragment>
                    <p className="cloud-content-text126 thq-body-small">
                      Maximize your cloud investment with our cloud cost
                      optimization services. We provide in-depth analysis and
                      actionable insights to help you manage and reduce your
                      cloud spending on GCP, AWS, and Azure. Our experts will
                      identify inefficiencies, recommend cost-saving measures,
                      and implement automated solutions to ensure you get the
                      best value from your cloud services without compromising
                      performance or scalability.
                    </p>
                  </Fragment>
                )}
              </p>
            </li>
            <li className="list-item">
              <h3 className="cloud-content-heading42">
                {props.heading42 ?? (
                  <Fragment>
                    <h3 className="cloud-content-text003 thq-heading-3">
                      Architecture &amp; Migration
                    </h3>
                  </Fragment>
                )}
              </h3>
              <p className="cloud-content-content42">
                {props.content42 ?? (
                  <Fragment>
                    <p className="cloud-content-text006 thq-body-small">
                      Design and implement a robust cloud infrastructure with
                      our cloud architecture and migration services. Our team of
                      experienced architects will help you build a scalable,
                      resilient, and high-performance cloud environment on GCP,
                      AWS, or Azure. We offer end-to-end migration services,
                      from initial planning and design to execution and
                      optimization, ensuring a smooth transition with minimal
                      disruption to your business operations.
                    </p>
                  </Fragment>
                )}
              </p>
            </li>
            <li className="list-item">
              <h3 className="cloud-content-heading43">
                {props.heading43 ?? (
                  <Fragment>
                    <h3 className="cloud-content-text008 thq-heading-3">
                      Hybrid Cloud Solution
                    </h3>
                  </Fragment>
                )}
              </h3>
              <p className="cloud-content-content43">
                {props.content43 ?? (
                  <Fragment>
                    <p className="cloud-content-text121 thq-body-small">
                      Achieve the perfect balance between on-premises and cloud
                      environments with our hybrid cloud solutions. We provide
                      customized strategies that integrate your existing
                      infrastructure with GCP, AWS, or Azure, enabling seamless
                      data flow and application interoperability. Our solutions
                      offer flexibility, scalability, and enhanced security,
                      allowing you to leverage the best of both worlds while
                      maintaining control over your critical workloads.
                    </p>
                  </Fragment>
                )}
              </p>
            </li>
            <li className="list-item">
              <h3 className="cloud-content-heading44">
                {props.heading44 ?? (
                  <Fragment>
                    <h3 className="cloud-content-text122 thq-heading-3">
                      Application Modernization
                    </h3>
                  </Fragment>
                )}
              </h3>
              <p className="cloud-content-content44">
                {props.content44 ?? (
                  <Fragment>
                    <p className="cloud-content-text115 thq-body-small">
                      Transform your legacy applications with our cloud
                      application modernization services. We help you update and
                      refactor your applications to take full advantage of the
                      capabilities offered by GCP, AWS, and Azure. Our services
                      include containerization, microservices architecture, and
                      serverless computing, enabling you to enhance performance,
                      scalability, and agility while reducing operational costs.
                    </p>
                  </Fragment>
                )}
              </p>
            </li>
            <li className="list-item">
              <h3 className="cloud-content-heading45">
                {props.heading45 ?? (
                  <Fragment>
                    <h3 className="cloud-content-text119 thq-heading-3">
                      Training &amp; Certification
                    </h3>
                  </Fragment>
                )}
              </h3>
              <p className="cloud-content-content45">
                {props.content45 ?? (
                  <Fragment>
                    <p className="cloud-content-text114 thq-body-small">
                      Empower your team with our comprehensive cloud training
                      and certification programs. We offer a range of courses
                      designed to equip your staff with the knowledge and skills
                      needed to effectively manage and utilize GCP, AWS, and
                      Azure services. Our training programs cover all levels,
                      from foundational to advanced, and include hands-on labs,
                      real-world scenarios, and official certification
                      preparation to ensure your team is cloud-ready.
                    </p>
                  </Fragment>
                )}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

CloudContent.defaultProps = {
  heading28: undefined,
  heading23: undefined,
  heading25: undefined,
  heading42: undefined,
  content4: undefined,
  content2: undefined,
  content42: undefined,
  heading27: undefined,
  heading43: undefined,
  content1: undefined,
  heading29: undefined,
  content3: undefined,
  content45: undefined,
  content44: undefined,
  heading26: undefined,
  heading4: undefined,
  heading24: undefined,
  heading45: undefined,
  heading3: undefined,
  content43: undefined,
  heading44: undefined,
  heading2: undefined,
  heading22: undefined,
  heading41: undefined,
  content41: undefined,
  heading21: undefined,
};

CloudContent.propTypes = {
  heading28: PropTypes.element,
  heading23: PropTypes.element,
  heading25: PropTypes.element,
  heading42: PropTypes.element,
  content4: PropTypes.element,
  content2: PropTypes.element,
  content42: PropTypes.element,
  heading27: PropTypes.element,
  heading43: PropTypes.element,
  content1: PropTypes.element,
  heading29: PropTypes.element,
  content3: PropTypes.element,
  content45: PropTypes.element,
  content44: PropTypes.element,
  heading26: PropTypes.element,
  heading4: PropTypes.element,
  heading24: PropTypes.element,
  heading45: PropTypes.element,
  heading3: PropTypes.element,
  content43: PropTypes.element,
  heading44: PropTypes.element,
  heading2: PropTypes.element,
  heading22: PropTypes.element,
  heading41: PropTypes.element,
  content41: PropTypes.element,
  heading21: PropTypes.element,
};

export default CloudContent;
