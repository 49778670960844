import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './about-dm-stats.css'

const AboutDMStats = (props) => {
  return (
    <div className="about-dm-stats-about-dm-stats thq-section-padding">
      <div className="about-dm-stats-max-width thq-section-max-width">
        <div className="about-dm-stats-container thq-flex-column">
          <img
            alt={props.imageAlt}
            src={props.imageSrc}
            className="thq-img-ratio-1-1 about-dm-stats-image"
          />
        </div>
        <div className="about-dm-stats-container1 thq-flex-column">
          <h2>
            {props.heading ?? (
              <Fragment>
                <h2 className="about-dm-stats-text09 thq-heading-2">
                  Our Digital Marketing Stats
                </h2>
              </Fragment>
            )}
          </h2>
          <div className="about-dm-stats-container2 thq-grid-2">
            <div className="about-dm-stats-container3">
              <h2>
                {props.heading1 ?? (
                  <Fragment>
                    <h2 className="about-dm-stats-text12 thq-heading-2">
                      SEO Clients
                    </h2>
                  </Fragment>
                )}
              </h2>
              <span>
                {props.text ?? (
                  <Fragment>
                    <span className="about-dm-stats-text14 thq-body-small">
                      Managed 20+ clients for Search Engine Optimization helping
                      them generate organic traffic via improved rankings
                    </span>
                  </Fragment>
                )}
              </span>
            </div>
            <div className="about-dm-stats-container4">
              <h2>
                {props.heading2 ?? (
                  <Fragment>
                    <h2 className="about-dm-stats-text15 thq-heading-2">
                      Social Media Campaigns
                    </h2>
                  </Fragment>
                )}
              </h2>
              <span>
                {props.text1 ?? (
                  <Fragment>
                    <span className="about-dm-stats-text10 thq-body-small">
                      We have run over 400+ successful campaigns across various
                      platforms like Facebook, Instagram, X (Twitter), TikTok,
                      Pinterest, and more
                    </span>
                  </Fragment>
                )}
              </span>
            </div>
          </div>
          <div className="about-dm-stats-container5 thq-grid-2">
            <div className="about-dm-stats-container6">
              <h2>
                {props.heading3 ?? (
                  <Fragment>
                    <h2 className="about-dm-stats-text17 thq-heading-2">
                      PPC Ads Managed
                    </h2>
                  </Fragment>
                )}
              </h2>
              <span>
                {props.text2 ?? (
                  <Fragment>
                    <span className="about-dm-stats-text13 thq-body-small">
                      Over 300 pay-per-click advertising campaigns have been
                      managed by our team.
                    </span>
                  </Fragment>
                )}
              </span>
            </div>
            <div className="about-dm-stats-container7">
              <h2>
                {props.heading4 ?? (
                  <Fragment>
                    <h2 className="about-dm-stats-text16 thq-heading-2">
                      Websites Designed
                    </h2>
                  </Fragment>
                )}
              </h2>
              <span>
                {props.text3 ?? (
                  <Fragment>
                    <span className="about-dm-stats-text11 thq-body-small">
                      Our expert designers and developers have designed and
                      developed over 30 Static and Dynamic websites enhancing
                      the online presence of the clients.
                    </span>
                  </Fragment>
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

AboutDMStats.defaultProps = {
  heading: undefined,
  text1: undefined,
  text3: undefined,
  heading1: undefined,
  imageSrc:
    'https://images.unsplash.com/photo-1452830978618-d6feae7d0ffa?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMTA2MDYxMnw&ixlib=rb-4.0.3&q=80&w=1500',
  text2: undefined,
  text: undefined,
  imageAlt: 'SEO Clients Image',
  heading2: undefined,
  heading4: undefined,
  heading3: undefined,
}

AboutDMStats.propTypes = {
  heading: PropTypes.element,
  text1: PropTypes.element,
  text3: PropTypes.element,
  heading1: PropTypes.element,
  imageSrc: PropTypes.string,
  text2: PropTypes.element,
  text: PropTypes.element,
  imageAlt: PropTypes.string,
  heading2: PropTypes.element,
  heading4: PropTypes.element,
  heading3: PropTypes.element,
}

export default AboutDMStats
