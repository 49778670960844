import React, { Fragment } from "react";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import AboutHeader from "../components/about-header";
import AboutIntro from "../components/about-intro";
import AboutDMStats from "../components/about-dm-stats";
import AboutCompStats from "../components/about-comp-stats";
import Footer from "../components/footer";
import "./about.css";

const About = (props) => {
  return (
    <div className="about-container">
      <Helmet>
        <title>About - SM Cloud</title>
        <meta property="og:title" content="About - SM Cloud" />
      </Helmet>
      <Navbar rootClassName="navbar-root-class-name2"></Navbar>
      <AboutHeader
        text={
          <Fragment>
            <h1 className="about-text thq-heading-1">SEO Services</h1>
          </Fragment>
        }
        text1={
          <Fragment>
            <p className="about-text01 thq-body-large">
              Choose from our wide variety of plans or reach us for a custom
              plan to carter your business needs
            </p>
          </Fragment>
        }
      ></AboutHeader>
      <AboutIntro
        text={
          <Fragment>
            <span className="about-text02">
              <span>
                SM Cloud is a complete Cloud Services, Web Development &amp;
                Digital Marketing company that provide, various Cloud services
                in GCP, AWS, and Azure, SEO Services, PPC Services, and Web
                designing services which have contributed to many startups who
                are looking to capitalize on limitless possibilities that
                digital marketing can provide. We have established our brand
                value on the basis of our immaculate results, tangible ROI,
                professional approach to problems and tailor-made solutions for
                any clients.
              </span>
              <br></br>
              <br></br>
              <span>
                We are a team of highly experienced Senior Developers, Designers
                and Digital Marketers who provide solutions around the world. We
                have vast experience in the Service and Product industry. Worked
                for startups and made them achieve their defined goals. We
                always look to save a large chunk of ad budget and thrive to
                achieve top ad placement and higher CTR. We have cemented our
                place in digital advertising and search engine marketing
                industry by earning the tag of a trustworthy digital company
                providing result-driven advertising and digital marketing
                solution. We are also actively serving international clientele
                with our wide market presence.
              </span>
            </span>
          </Fragment>
        }
      ></AboutIntro>
      <AboutDMStats
        text={
          <Fragment>
            <span className="about-text07 thq-body-small">
              Managed 20+ clients for Search Engine Optimization helping them
              generate organic traffic via improved rankings
            </span>
          </Fragment>
        }
        text1={
          <Fragment>
            <span className="about-text08 thq-body-small">
              We have run over 400+ successful campaigns across various
              platforms like Facebook, Instagram, X (Twitter), TikTok,
              Pinterest, and more
            </span>
          </Fragment>
        }
        text2={
          <Fragment>
            <span className="about-text09 thq-body-small">
              Over 300 pay-per-click advertising campaigns have been managed by
              our team.
            </span>
          </Fragment>
        }
        text3={
          <Fragment>
            <span className="about-text10 thq-body-small">
              Our expert designers and developers have designed and developed
              over 30 Static and Dynamic websites enhancing the online presence
              of the clients.
            </span>
          </Fragment>
        }
        heading={
          <Fragment>
            <h2 className="about-text11 thq-heading-2">
              Our Digital Marketing Stats
            </h2>
          </Fragment>
        }
        heading1={
          <Fragment>
            <h2 className="about-text12 thq-heading-2">SEO Clients</h2>
          </Fragment>
        }
        heading2={
          <Fragment>
            <h2 className="about-text13 thq-heading-2">
              Social Media Campaigns
            </h2>
          </Fragment>
        }
        heading3={
          <Fragment>
            <h2 className="about-text14 thq-heading-2">PPC Ads Managed</h2>
          </Fragment>
        }
        heading4={
          <Fragment>
            <h2 className="about-text15 thq-heading-2">Websites Designed</h2>
          </Fragment>
        }
      ></AboutDMStats>
      <AboutCompStats
        text={
          <Fragment>
            <span className="about-text16 thq-body-small">
              Years of Experience
            </span>
          </Fragment>
        }
        text1={
          <Fragment>
            <span className="about-text17 thq-body-small">
              Completed Digital Marketing Projects
            </span>
          </Fragment>
        }
        text2={
          <Fragment>
            <span className="about-text18 thq-body-small">Cloud Services</span>
          </Fragment>
        }
        text3={
          <Fragment>
            <span className="about-text19 thq-body-small">
              Active clients on 1 - year contract
            </span>
          </Fragment>
        }
        text4={
          <Fragment>
            <span className="about-text20 thq-body-small">
              On-time Project Delivery
            </span>
          </Fragment>
        }
        text5={
          <Fragment>
            <span className="about-text21 thq-body-small">
              Client Satisfaction Rate
            </span>
          </Fragment>
        }
        text6={
          <Fragment>
            <span className="about-text22 thq-body-small">Team Members</span>
          </Fragment>
        }
        title={
          <Fragment>
            <h2 className="about-text23 thq-heading-2">Company Stats</h2>
          </Fragment>
        }
        content={
          <Fragment>
            <span className="about-text24 thq-body-small">
              Our results in numbers
            </span>
          </Fragment>
        }
        heading={
          <Fragment>
            <h2 className="about-text25 thq-heading-2">2+</h2>
          </Fragment>
        }
        heading1={
          <Fragment>
            <h2 className="about-text26 thq-heading-2">80+</h2>
          </Fragment>
        }
        heading2={
          <Fragment>
            <h2 className="about-text27 thq-heading-2">20+</h2>
          </Fragment>
        }
        heading3={
          <Fragment>
            <h2 className="about-text28 thq-heading-2">15</h2>
          </Fragment>
        }
        heading4={
          <Fragment>
            <h2 className="about-text29 thq-heading-2">100%</h2>
          </Fragment>
        }
        heading5={
          <Fragment>
            <h2 className="about-text30 thq-heading-2">95%</h2>
          </Fragment>
        }
        heading6={
          <Fragment>
            <h2 className="about-text31 thq-heading-2">20+</h2>
          </Fragment>
        }
      ></AboutCompStats>
      <Footer rootClassName="footer-root-class-name1"></Footer>
    </div>
  );
};

export default About;
