import React from 'react'

import PropTypes from 'prop-types'

import './home-steps.css'

const HomeSteps = (props) => {
  return (
    <div
      className={`home-steps-container thq-section-padding ${props.rootClassName} `}
    >
      <div className="home-steps-max-width">
        <div className="home-steps-container1 thq-grid-2">
          <div className="home-steps-section-header">
            <h2 className="home-steps-text thq-heading-2">
              4-Step Approach to Comprehensive Solutions
            </h2>
            <p className="home-steps-text01 thq-body-large">
              Our 4-Step Methodology for Delivering Comprehensive Solutions
              ensures that each project is executed with precision and
              excellence. We begin with an in-depth analysis to understand your
              unique needs and challenges. Next, we develop a customized
              strategy tailored to achieve your specific goals. Our team then
              implements the solution with meticulous attention to detail,
              ensuring seamless integration and optimal performance. Finally, we
              provide ongoing support and continuous improvement to ensure
              long-term success. This structured approach guarantees that you
              receive a solution that not only meets but exceeds your
              expectations. Partner with us for reliable and effective
              solutions, every step of the way.
            </p>
          </div>
          <div className="home-steps-container2">
            <div className="home-steps-container3 thq-card">
              <h2 className="home-steps-text02 thq-heading-2">
                {props.step1Title}
              </h2>
              <span className="home-steps-text03 thq-body-small">
                {props.step1Description}
              </span>
              <label className="home-steps-text04 thq-heading-3">01</label>
            </div>
            <div className="home-steps-container4 thq-card">
              <h2 className="home-steps-text05 thq-heading-2">
                {props.step2Title}
              </h2>
              <span className="home-steps-text06 thq-body-small">
                {props.step2Description}
              </span>
              <label className="home-steps-text07 thq-heading-3">02</label>
            </div>
            <div className="home-steps-container5 thq-card">
              <h2 className="home-steps-text08 thq-heading-2">
                {props.step3Title}
              </h2>
              <span className="home-steps-text09 thq-body-small">
                {props.step3Description}
              </span>
              <label className="home-steps-text10 thq-heading-3">03</label>
            </div>
            <div className="home-steps-container6 thq-card">
              <h2 className="home-steps-text11 thq-heading-2">
                {props.step4Title}
              </h2>
              <span className="home-steps-text12 thq-body-small">
                {props.step4Description}
              </span>
              <label className="home-steps-text13 thq-heading-3">04</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

HomeSteps.defaultProps = {
  step1Title: 'Initial Consultation',
  step2Description:
    'Based on the consultation, we create a customized strategy tailored to your needs.',
  step4Title: 'Analysis and Optimization',
  step4Description:
    'We analyze the performance, optimize strategies, and provide detailed reports for transparency and improvement.',
  step1Description:
    'We start by understanding your business goals and objectives in a detailed consultation.',
  rootClassName: '',
  step3Description:
    'Our team executes the strategy using various tools & solutions to reach your desired results.',
  step2Title: 'Strategy Development',
  step3Title: 'Implementation',
}

HomeSteps.propTypes = {
  step1Title: PropTypes.string,
  step2Description: PropTypes.string,
  step4Title: PropTypes.string,
  step4Description: PropTypes.string,
  step1Description: PropTypes.string,
  rootClassName: PropTypes.string,
  step3Description: PropTypes.string,
  step2Title: PropTypes.string,
  step3Title: PropTypes.string,
}

export default HomeSteps
