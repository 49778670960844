import React from 'react'

import PropTypes from 'prop-types'

import './testimonial18.css'

const Testimonial18 = (props) => {
  return (
    <div className={`thq-section-padding ${props.rootClassName} `}>
      <div className="testimonial18-max-width thq-section-max-width">
        <div className="testimonial18-container thq-grid-2">
          <div className="testimonial18-section-title">
            <h2 className="thq-heading-2">{props.heading1}</h2>
            <p className="thq-body-large">{props.content1}</p>
            <div className="testimonial18-container1">
              <button
                type="button"
                className="thq-button-filled testimonial18-button"
              >
                {props.action1}
              </button>
              <button
                type="button"
                className="thq-button-outline testimonial18-button1"
              >
                {props.action2}
              </button>
            </div>
          </div>
          <div className="testimonial18-content">
            <div className="testimonial18-column thq-card">
              <div className="testimonial18-stars">
                <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                  <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                </svg>
                <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                  <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                </svg>
                <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                  <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                </svg>
                <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                  <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                </svg>
                <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                  <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                </svg>
              </div>
              <span className="testimonial18-text thq-body-small">
                {props.review1}
              </span>
              <div className="testimonial18-avatar">
                <img
                  alt={props.author1Alt}
                  src={props.author1Src}
                  className="testimonial18-avatar-image thq-img-round thq-img-ratio-1-1"
                />
                <div className="testimonial18-avatar-content">
                  <span className="testimonial18-text01 thq-body-small">
                    {props.author1Name}
                  </span>
                  <span className="testimonial18-text02 thq-body-small">
                    {props.author1Position}
                  </span>
                </div>
              </div>
            </div>
            <div className="testimonial18-column1 thq-card">
              <div className="testimonial18-stars1">
                <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                  <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                </svg>
                <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                  <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                </svg>
                <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                  <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                </svg>
                <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                  <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                </svg>
                <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                  <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                </svg>
              </div>
              <span className="testimonial18-text03 thq-body-small">
                {props.review2}
              </span>
              <div className="testimonial18-avatar1">
                <img
                  alt={props.author2Alt}
                  src={props.author2Src}
                  className="testimonial18-avatar-image1 thq-img-round thq-img-ratio-1-1"
                />
                <div className="testimonial18-avatar-content1">
                  <span className="testimonial18-text04 thq-body-small">
                    {props.author2}
                  </span>
                  <span className="testimonial18-text05 thq-body-small">
                    {props.author2Position}
                  </span>
                </div>
              </div>
            </div>
            <div className="testimonial18-column2 thq-card">
              <div className="testimonial18-stars2">
                <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                  <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                </svg>
                <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                  <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                </svg>
                <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                  <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                </svg>
                <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                  <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                </svg>
                <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                  <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                </svg>
              </div>
              <span className="testimonial18-text06 thq-body-small">
                {props.review3}
              </span>
              <div className="testimonial18-avatar2">
                <img
                  alt={props.author3Alt}
                  src={props.author3Src}
                  className="testimonial18-avatar-image2 thq-img-round thq-img-ratio-1-1"
                />
                <div className="testimonial18-avatar-content2">
                  <span className="testimonial18-text07 thq-body-small">
                    {props.author3}
                  </span>
                  <span className="testimonial18-text08 thq-body-small">
                    {props.author3Position}
                  </span>
                </div>
              </div>
            </div>
            <div className="testimonial18-column3 thq-card">
              <div className="testimonial18-stars3">
                <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                  <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                </svg>
                <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                  <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                </svg>
                <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                  <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                </svg>
                <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                  <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                </svg>
                <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                  <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                </svg>
              </div>
              <span className="testimonial18-review4 thq-body-small">
                {props.review4}
              </span>
              <div className="testimonial18-avatar3">
                <img
                  alt={props.author4Alt}
                  src={props.author4Src}
                  className="testimonial18-author4 thq-img-round thq-img-ratio-1-1"
                />
                <div className="testimonial18-avatar-content3">
                  <span className="testimonial18-author41 thq-body-small">
                    {props.author4}
                  </span>
                  <span className="testimonial18-text09 thq-body-small">
                    {props.author4Position}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Testimonial18.defaultProps = {
  author1Name: 'John Doe',
  author2Alt: 'Client 2',
  author4: 'Author Name',
  author2Src:
    'https://images.unsplash.com/photo-1613951085587-cfe5d0a6cffc?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMTA2MDQyM3w&ixlib=rb-4.0.3&q=80&w=1080',
  author4Src:
    'https://images.unsplash.com/photo-1500625597061-d472abd2afbb?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMTA2MDQyM3w&ixlib=rb-4.0.3&q=80&w=1080',
  author1Position: 'CEO, Company ABC',
  author3Src:
    'https://images.unsplash.com/photo-1573497019940-1c28c88b4f3e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMTA2MDQyNHw&ixlib=rb-4.0.3&q=80&w=1080',
  content1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  action2: 'Secondary action',
  author2: 'Author Name',
  author1Src:
    'https://images.unsplash.com/photo-1611695434369-a8f5d76ceb7b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMTA2MDQyM3w&ixlib=rb-4.0.3&q=80&w=1080',
  action1: 'Primary action',
  author3Position: 'Owner, Business XYZ',
  author4Alt: 'Client 4',
  author3Alt: 'Client 3',
  author3: 'Author Name',
  author4Position: 'Founder, Startup ABC',
  review4:
    'The customized marketing plan they created for us was spot on. They took the time to understand our business goals and tailored their strategies accordingly. Highly recommend their services.',
  author2Position: 'Marketing Director, XYZ Inc.',
  review2:
    'The social media marketing services provided by the team have helped us engage with our audience better and increase brand awareness. Their expertise in this area is unmatched.',
  rootClassName: '',
  heading1: 'Client Testimonials',
  review3:
    "I opted for their PPC advertising services, and I'm thrilled with the results. Our conversion rates have gone up, and we are seeing a positive ROI on our ad spend.",
  review1:
    'Working with the digital marketing company has been a game-changer for our business. Their SEO strategies have significantly improved our online visibility and brought in more organic traffic.',
  author1Alt: 'Client 1',
}

Testimonial18.propTypes = {
  author1Name: PropTypes.string,
  author2Alt: PropTypes.string,
  author4: PropTypes.string,
  author2Src: PropTypes.string,
  author4Src: PropTypes.string,
  author1Position: PropTypes.string,
  author3Src: PropTypes.string,
  content1: PropTypes.string,
  action2: PropTypes.string,
  author2: PropTypes.string,
  author1Src: PropTypes.string,
  action1: PropTypes.string,
  author3Position: PropTypes.string,
  author4Alt: PropTypes.string,
  author3Alt: PropTypes.string,
  author3: PropTypes.string,
  author4Position: PropTypes.string,
  review4: PropTypes.string,
  author2Position: PropTypes.string,
  review2: PropTypes.string,
  rootClassName: PropTypes.string,
  heading1: PropTypes.string,
  review3: PropTypes.string,
  review1: PropTypes.string,
  author1Alt: PropTypes.string,
}

export default Testimonial18
