import React, { Fragment } from "react";

import PropTypes from "prop-types";

import "./web-d-content.css";

const WebDContent = (props) => {
  return (
    <div className="web-d-content-web-d-content thq-section-padding">
      <div className="web-d-content-max-width thq-section-max-width">
        <div className="web-d-content-content thq-flex-column">
          <ul className="web-d-content-ul thq-flex-column">
            <li className="thq-flex-column list-item">
              <h2>
                {props.heading1 ?? (
                  <Fragment>
                    <h2 className="web-d-content-text17 thq-heading-2">
                      Transforming Ideas into Digital Experiences
                    </h2>
                  </Fragment>
                )}
              </h2>
              <p>
                {props.content1 ?? (
                  <Fragment>
                    <p className="web-d-content-content11 thq-body-small">
                      <span>
                        At SM Cloud, we specialize in crafting bespoke websites
                        that not only look stunning but also deliver exceptional
                        user experiences. Whether you&apos;re a startup looking
                        to establish an online presence or an established
                        business seeking a website overhaul, our team of skilled
                        designers and developers is here to bring your vision to
                        life.
                      </span>
                      <br className="web-d-content-text03"></br>
                    </p>
                  </Fragment>
                )}
              </p>
            </li>
            <li className="list-item">
              <h3>
                {props.heading2 ?? (
                  <Fragment>
                    <h3 className="web-d-content-text06 thq-heading-3">
                      Website Development Services at SM Cloud include:
                    </h3>
                  </Fragment>
                )}
              </h3>
              <h3 className="web-d-content-heading21">
                {props.heading21 ?? (
                  <Fragment>
                    <h3 className="web-d-content-text14 thq-heading-3">
                      ➤ Static Website Development
                    </h3>
                  </Fragment>
                )}
              </h3>
              <h3 className="web-d-content-heading22">
                {props.heading22 ?? (
                  <Fragment>
                    <h3 className="web-d-content-text12 thq-heading-3">
                      ➤ Dynamic Website Development
                    </h3>
                  </Fragment>
                )}
              </h3>
              <h3 className="web-d-content-heading23">
                {props.heading23 ?? (
                  <Fragment>
                    <h3 className="web-d-content-text11 thq-heading-3">
                      ➤ E-Commerce Website Development
                    </h3>
                  </Fragment>
                )}
              </h3>
              <h3 className="web-d-content-heading24">
                {props.heading24 ?? (
                  <Fragment>
                    <h3 className="web-d-content-text15 thq-heading-3">
                      ➤ Domain Management &amp; Hosting
                    </h3>
                  </Fragment>
                )}
              </h3>
            </li>
            <li className="list-item">
              <h3>
                {props.heading25 ?? (
                  <Fragment>
                    <h3 className="web-d-content-text01 thq-heading-3">
                      Static Website Development
                    </h3>
                  </Fragment>
                )}
              </h3>
              <p>
                {props.content2 ?? (
                  <Fragment>
                    <p className="web-d-content-text10 thq-body-small">
                      Static website development is the ideal solution for
                      businesses and individuals looking to establish a
                      straightforward and professional online presence. These
                      websites consist of fixed content, which means each page
                      is pre-built and displays the same information to every
                      visitor. This simplicity not only ensures faster load
                      times and enhanced security but also reduces maintenance
                      requirements. Perfect for portfolios, informational sites,
                      and small business pages, our static websites deliver a
                      seamless user experience while keeping costs manageable.
                    </p>
                  </Fragment>
                )}
              </p>
            </li>
            <li className="list-item">
              <h3>
                {props.heading3 ?? (
                  <Fragment>
                    <h3 className="web-d-content-text16 thq-heading-3">
                      Dynamic Website Development
                    </h3>
                  </Fragment>
                )}
              </h3>
              <p>
                {props.content3 ?? (
                  <Fragment>
                    <p className="web-d-content-content31 thq-body-small">
                      <span>
                        Dynamic website development offers a more interactive
                        and engaging user experience by generating content
                        on-the-fly based on user interactions or data inputs.
                        These websites are built using server-side technologies
                        and databases, allowing for features such as user
                        accounts, personalized content, and real-time updates.
                        Whether you need a blog, a membership site, or a complex
                        web application, our dynamic website solutions are
                        designed to adapt to your growing needs, ensuring your
                        online presence remains fresh and relevant.
                      </span>
                      <br></br>
                    </p>
                  </Fragment>
                )}
              </p>
            </li>
            <li className="list-item">
              <h3>
                {props.heading4 ?? (
                  <Fragment>
                    <h3 className="web-d-content-text thq-heading-3">
                      E-Commerce Website Development
                    </h3>
                  </Fragment>
                )}
              </h3>
              <p>
                {props.content4 ?? (
                  <Fragment>
                    <p className="web-d-content-text13 thq-body-small">
                      Ecommerce website development empowers businesses to sell
                      products and services online, reaching a global audience
                      and operating 24/7. Our ecommerce solutions are crafted to
                      provide a seamless shopping experience, featuring secure
                      payment gateways, intuitive product navigation, and
                      efficient checkout processes. We integrate advanced
                      functionalities such as inventory management, customer
                      reviews, and personalized recommendations to enhance user
                      satisfaction and boost sales. From small online stores to
                      large-scale marketplaces, we tailor our ecommerce
                      platforms to meet your specific business requirements.
                    </p>
                  </Fragment>
                )}
              </p>
            </li>
            <li className="list-item">
              <h3>
                {props.heading41 ?? (
                  <Fragment>
                    <h3 className="web-d-content-text07 thq-heading-3">
                      Domain Management &amp; Hosting
                    </h3>
                  </Fragment>
                )}
              </h3>
              <p>
                {props.content41 ?? (
                  <Fragment>
                    <p className="web-d-content-content42 thq-body-small">
                      <span>
                        Effective domain management and reliable hosting are
                        crucial for maintaining a robust online presence. We
                        offer comprehensive domain management services,
                        including registration, renewal, and transfer of
                        domains, ensuring your web address is always secure and
                        functional. Our hosting solutions guarantee high
                        performance, minimal downtime, and scalability to handle
                        increasing traffic. With our managed hosting plans, you
                        can focus on your core business activities while we take
                        care of server maintenance, updates, and backups,
                        providing a hassle-free experience and peace of mind.
                      </span>
                      <br></br>
                    </p>
                  </Fragment>
                )}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

WebDContent.defaultProps = {
  heading4: undefined,
  heading25: undefined,
  content1: undefined,
  content3: undefined,
  heading2: undefined,
  heading41: undefined,
  content41: undefined,
  content2: undefined,
  heading23: undefined,
  heading22: undefined,
  content4: undefined,
  heading21: undefined,
  heading24: undefined,
  heading3: undefined,
  heading1: undefined,
};

WebDContent.propTypes = {
  heading4: PropTypes.element,
  heading25: PropTypes.element,
  content1: PropTypes.element,
  content3: PropTypes.element,
  heading2: PropTypes.element,
  heading41: PropTypes.element,
  content41: PropTypes.element,
  content2: PropTypes.element,
  heading23: PropTypes.element,
  heading22: PropTypes.element,
  content4: PropTypes.element,
  heading21: PropTypes.element,
  heading24: PropTypes.element,
  heading3: PropTypes.element,
  heading1: PropTypes.element,
};

export default WebDContent;
