import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './pricing-contact-card.css'

const PricingContactCard = (props) => {
  return (
    <div className="pricing-contact-card-pricing-contact-card thq-section-padding">
      <div className="pricing-contact-card-max-width thq-section-max-width"></div>
      <div className="thq-section-padding">
        <div className="thq-section-max-width">
          <div className="pricing-contact-card-accent2-bg">
            <div className="pricing-contact-card-accent1-bg">
              <div className="pricing-contact-card-container1">
                <div className="pricing-contact-card-content">
                  <span>
                    {props.text ?? (
                      <Fragment>
                        <span className="pricing-contact-card-text3 thq-heading-2">
                          Looking for Cloud Computing, SEO or other services??
                        </span>
                      </Fragment>
                    )}
                  </span>
                  <p>
                    {props.text1 ?? (
                      <Fragment>
                        <p className="pricing-contact-card-text5 thq-body-large">
                          Let our experts create a plan best suited for your
                          needs and budget
                        </p>
                      </Fragment>
                    )}
                  </p>
                </div>
                <div className="pricing-contact-card-actions">
                  <Link
                    to="/contact-us"
                    className="pricing-contact-card-navlink thq-button-filled"
                  >
                    <span>
                      {props.button ?? (
                        <Fragment>
                          <span className="pricing-contact-card-text4">
                            Get a custom quote
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

PricingContactCard.defaultProps = {
  text: undefined,
  button: undefined,
  text1: undefined,
}

PricingContactCard.propTypes = {
  text: PropTypes.element,
  button: PropTypes.element,
  text1: PropTypes.element,
}

export default PricingContactCard
