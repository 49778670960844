import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './header.css'

const Header = (props) => {
  return (
    <div className="header-container">
      <h3>
        {props.heading ?? (
          <Fragment>
            <h3 className="header-text5">OOPS! PAGE NOT FOUND</h3>
          </Fragment>
        )}
      </h3>
      <div className="header-container1">
        <h1>
          {props.heading1 ?? (
            <Fragment>
              <h1 className="header-text3">404</h1>
            </Fragment>
          )}
        </h1>
      </div>
      <div className="header-container2">
        <h2>
          {props.heading2 ?? (
            <Fragment>
              <h2 className="header-text4">
                WE ARE SORRY, BUT THE PAGE YOU REQUESTED WAS NOT FOUND
              </h2>
            </Fragment>
          )}
        </h2>
      </div>
    </div>
  )
}

Header.defaultProps = {
  heading1: undefined,
  heading2: undefined,
  heading: undefined,
}

Header.propTypes = {
  heading1: PropTypes.element,
  heading2: PropTypes.element,
  heading: PropTypes.element,
}

export default Header
