import React from "react";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import ContactForm from "../components/contact-form";
import ContactOptions from "../components/contact-options";
import Footer from "../components/footer";
import "./contact-us.css";

const ContactUs = (props) => {
  return (
    <div className="contact-us-container">
      <Helmet>
        <title>Contact-Us - SM Cloud</title>
        <meta property="og:title" content="Contact-Us - SM Cloud" />
      </Helmet>
      <Navbar></Navbar>
      <ContactForm content1="For inquiries and appointments, please visit our head office during business hours."></ContactForm>
      <ContactOptions email1="info@smcloudsolutions.com"></ContactOptions>
      <Footer rootClassName="footer-root-class-name4"></Footer>
    </div>
  );
};

export default ContactUs;
