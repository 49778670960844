import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './services-header.css'

const ServicesHeader = (props) => {
  return (
    <div
      className={`services-header-header30 thq-section-padding ${props.rootClassName} `}
    >
      <img
        alt={props.image1Alt}
        src={props.image1Src}
        className="services-header-image"
      />
      <div className="services-header-container"></div>
      <div className="services-header-max-width thq-section-max-width">
        <div className="services-header-content">
          <h1 className="services-header-text thq-heading-1">
            {props.heading1}
          </h1>
          <p className="services-header-text1 thq-body-large">
            {props.content1}
          </p>
          <div className="services-header-actions">
            <Link
              to="/contact-us"
              className="services-header-button thq-button-filled"
            >
              <span className="services-header-text2 thq-body-small">
                {props.action1}
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

ServicesHeader.defaultProps = {
  content1:
    'We offer a range of services including Cloud Services, SEO, social media marketing, PPC advertising, and website design to help your business succeed online.',
  image1Src:
    'https://images.unsplash.com/photo-1620313926202-159eb90ec94a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMTA2MDY4MXw&ixlib=rb-4.0.3&q=80&w=1080',
  heading1: 'Boost Your Digital Growth with Our Comprehensive Services',
  action1: 'Get Quote',
  image1Alt: 'Digital Marketing Services',
  rootClassName: '',
}

ServicesHeader.propTypes = {
  content1: PropTypes.string,
  image1Src: PropTypes.string,
  heading1: PropTypes.string,
  action1: PropTypes.string,
  image1Alt: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default ServicesHeader
