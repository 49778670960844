import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import FeatureCard from './feature-card'
import './home-why-us.css'

const HomeWhyUs = (props) => {
  return (
    <div className={`home-why-us-container ${props.rootClassName} `}>
      <h1>
        {props.heading ?? (
          <Fragment>
            <h1 className="home-why-us-text10">Why Choose Us?</h1>
          </Fragment>
        )}
      </h1>
      <div className="home-why-us-container01">
        <FeatureCard
          text={
            <Fragment>
              <span className="home-why-us-text01">
                We understand that every business is unique. Our solutions are
                customized to meet your specific needs and goals.
              </span>
            </Fragment>
          }
          heading={
            <Fragment>
              <h1 className="home-why-us-text02">Tailored Solutions:</h1>
            </Fragment>
          }
          imageSrc="/settings-200h.webp"
        ></FeatureCard>
        <div className="home-why-us-container02">
          <div className="home-why-us-container03">
            <svg
              viewBox="0 0 884.5897142857142 1024"
              className="home-why-us-icon"
            >
              <path d="M25.714 943.429c-14.286 14.286-25.714 9.143-25.714-10.857v-841.143c0-20 11.429-25.143 25.714-10.857l405.714 405.714c3.429 3.429 5.714 6.857 7.429 10.857v-405.714c0-20 11.429-25.143 25.714-10.857l405.714 405.714c14.286 14.286 14.286 37.143 0 51.429l-405.714 405.714c-14.286 14.286-25.714 9.143-25.714-10.857v-405.714c-1.714 4-4 7.429-7.429 10.857z"></path>
            </svg>
          </div>
          <div className="home-why-us-container04">
            <h1>
              {props.heading1 ?? (
                <Fragment>
                  <h1 className="home-why-us-text14">
                    Cutting-Edge Technology:
                  </h1>
                </Fragment>
              )}
            </h1>
            <span>
              {props.text ?? (
                <Fragment>
                  <span className="home-why-us-text11">
                    We stay ahead of the curve by utilizing the latest
                    technologies and best practices to ensure your business
                    stays competitive.
                  </span>
                </Fragment>
              )}
            </span>
          </div>
        </div>
        <div className="home-why-us-container05">
          <div className="home-why-us-container06">
            <svg viewBox="0 0 1024 1024" className="home-why-us-icon2">
              <path d="M320 192c0-106.039 85.961-192 192-192s192 85.961 192 192c0 106.039-85.961 192-192 192s-192-85.961-192-192zM768.078 448h-35.424l-199.104 404.244 74.45-372.244-96-96-96 96 74.45 372.244-199.102-404.244h-35.424c-127.924 0-127.924 85.986-127.924 192v320h768v-320c0-106.014 0-192-127.922-192z"></path>
            </svg>
          </div>
          <div className="home-why-us-container07">
            <h1>
              {props.heading2 ?? (
                <Fragment>
                  <h1 className="home-why-us-text15">
                    Expertise and Experience:
                  </h1>
                </Fragment>
              )}
            </h1>
            <span>
              {props.text1 ?? (
                <Fragment>
                  <span className="home-why-us-text13">
                    With years of experience in the industry, our professionals
                    bring deep knowledge and a proven track record to every
                    project.
                  </span>
                </Fragment>
              )}
            </span>
          </div>
        </div>
        <div className="home-why-us-container08">
          <div className="home-why-us-container09">
            <svg
              viewBox="0 0 877.7142857142857 1024"
              className="home-why-us-icon4"
            >
              <path d="M585.143 512c0 80.571-65.714 146.286-146.286 146.286s-146.286-65.714-146.286-146.286 65.714-146.286 146.286-146.286 146.286 65.714 146.286 146.286zM658.286 512c0-121.143-98.286-219.429-219.429-219.429s-219.429 98.286-219.429 219.429 98.286 219.429 219.429 219.429 219.429-98.286 219.429-219.429zM731.429 512c0 161.714-130.857 292.571-292.571 292.571s-292.571-130.857-292.571-292.571 130.857-292.571 292.571-292.571 292.571 130.857 292.571 292.571zM804.571 512c0-201.714-164-365.714-365.714-365.714s-365.714 164-365.714 365.714 164 365.714 365.714 365.714 365.714-164 365.714-365.714zM877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
            </svg>
          </div>
          <div className="home-why-us-container10">
            <h1>
              {props.heading3 ?? (
                <Fragment>
                  <h1 className="home-why-us-text12">
                    Customer-Centric Approach:
                  </h1>
                </Fragment>
              )}
            </h1>
            <span>
              {props.text2 ?? (
                <Fragment>
                  <span className="home-why-us-text09">
                    With years of experience in the industry, our professionals
                    bring deep knowledge and a proven track record to every
                    project.
                  </span>
                </Fragment>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

HomeWhyUs.defaultProps = {
  text2: undefined,
  heading: undefined,
  text: undefined,
  rootClassName: '',
  heading3: undefined,
  text1: undefined,
  heading1: undefined,
  heading2: undefined,
}

HomeWhyUs.propTypes = {
  text2: PropTypes.element,
  heading: PropTypes.element,
  text: PropTypes.element,
  rootClassName: PropTypes.string,
  heading3: PropTypes.element,
  text1: PropTypes.element,
  heading1: PropTypes.element,
  heading2: PropTypes.element,
}

export default HomeWhyUs
