import React from 'react'

import PropTypes from 'prop-types'

import './cta2.css'

const CTA2 = (props) => {
  return (
    <div className="cta2-container thq-section-padding">
      <div className="cta2-max-width thq-section-max-width">
        <div className="cta2-content">
          <h2 className="thq-heading-2">{props.heading1}</h2>
          <p className="thq-body-large">{props.content1}</p>
          <div className="cta2-container1">
            <div className="cta2-row thq-flex-column">
              <div className="cta2-container2">
                <input
                  type="text"
                  placeholder="Enter your email"
                  className="thq-input"
                />
                <button type="button" className="thq-button-filled">
                  {props.action1}
                </button>
              </div>
            </div>
            <span className="thq-body-small">{props.content2}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

CTA2.defaultProps = {
  content2:
    'Let us take your online presence to the next level with our tailored marketing strategies.',
  content1:
    'Contact us today to discuss how our digital marketing services can help your business thrive.',
  heading1: 'Ready to boost your online presence?',
  action1: 'Get in Touch',
}

CTA2.propTypes = {
  content2: PropTypes.string,
  content1: PropTypes.string,
  heading1: PropTypes.string,
  action1: PropTypes.string,
}

export default CTA2
