import React, { Fragment } from "react";

import Script from "dangerous-html/react";
import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import HomeHeader from "../components/home-header";
import HomeWhyUs from "../components/home-why-us";
import HomeServCloud from "../components/home-serv-cloud";
import HomeServMarketing from "../components/home-serv-marketing";
import HomeContactCard from "../components/home-contact-card";
import HomeSteps from "../components/home-steps";
import Contact from "../components/contact";
import HomeTestimonial from "../components/home-testimonial";
import Footer from "../components/footer";
import "./home.css";

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>SM Cloud</title>
      </Helmet>
      <Navbar rootClassName="navbar-root-class-name"></Navbar>
      <HomeHeader
        text1={
          <Fragment>
            <span className="home-text">
              At SM Cloud, we empower your business to reach new heights with
              our comprehensive range of services in Cloud Computing, Web
              Development, Digital Marketing, SEO, and more. Whether you&apos;re
              looking to optimize your cloud infrastructure, create a stunning
              website, or enhance your online presence, our team of experts is
              here to help you succeed.
            </span>
          </Fragment>
        }
        heading={
          <Fragment>
            <h1 className="home-text01">Welcome to SM Cloud</h1>
          </Fragment>
        }
        heading2={
          <Fragment>
            <h1 className="home-text02">
              Your Partner in Digital Transformation
            </h1>
          </Fragment>
        }
        rootClassName="home-header-root-class-name"
      ></HomeHeader>
      <HomeWhyUs
        text={
          <Fragment>
            <span className="home-text03">
              We stay ahead of the curve by utilizing the latest technologies
              and best practices to ensure your business stays competitive.
            </span>
          </Fragment>
        }
        text1={
          <Fragment>
            <span className="home-text04">
              With years of experience in the industry, our professionals bring
              deep knowledge and a proven track record to every project.
            </span>
          </Fragment>
        }
        text2={
          <Fragment>
            <span className="home-text05">
              With years of experience in the industry, our professionals bring
              deep knowledge and a proven track record to every project.
            </span>
          </Fragment>
        }
        heading={
          <Fragment>
            <h1 className="home-text06">Why Choose Us?</h1>
          </Fragment>
        }
        heading1={
          <Fragment>
            <h1 className="home-text07">Cutting-Edge Technology:</h1>
          </Fragment>
        }
        heading2={
          <Fragment>
            <h1 className="home-text08">Expertise and Experience:</h1>
          </Fragment>
        }
        heading3={
          <Fragment>
            <h1 className="home-text09">Customer-Centric Approach:</h1>
          </Fragment>
        }
        rootClassName="home-why-us-root-class-name"
      ></HomeWhyUs>
      <HomeServCloud rootClassName="home-serv-cloud-root-class-name"></HomeServCloud>
      <HomeServMarketing rootClassName="home-serv-marketing-root-class-name"></HomeServMarketing>
      <HomeContactCard rootClassName="home-contact-card-root-class-name"></HomeContactCard>
      <HomeSteps rootClassName="home-steps-root-class-name"></HomeSteps>
      <Contact rootClassName="contact-root-class-name"></Contact>
      <HomeTestimonial
        text={
          <Fragment>
            <span className="home-text10 thq-body-small">
              <span>
                Hear from our satisfied clients about their success stories with
                our top-notch cloud solutions, digital marketing, SEO, and web
                development services. Their testimonials reflect our commitment
                to excellence and the transformative impact we&apos;ve made on
                their businesses. Discover how we&apos;ve helped them achieve
                their goals and drive growth.
              </span>
              <br></br>
            </span>
          </Fragment>
        }
        rootClassName="home-testimonial-root-class-name"
      ></HomeTestimonial>
      <Footer rootClassName="footer-root-class-name12"></Footer>
    </div>
  );
};

export default Home;
