import React, { Fragment } from "react";

import PropTypes from "prop-types";

import "./home-testimonial.css";

const HomeTestimonial = (props) => {
  return (
    <div className={`thq-section-padding ${props.rootClassName} `}>
      <div className="home-testimonial-max-width thq-section-max-width">
        <div className="home-testimonial-container">
          <h2 className="home-testimonial-text thq-heading-2">
            {props.heading1}
          </h2>
          <span>
            {props.text ?? (
              <Fragment>
                <span className="home-testimonial-text14 thq-body-small">
                  <span>
                    Hear from our satisfied clients about their success stories
                    with our top-notch cloud solutions, digital marketing, SEO,
                    and web development services. Their testimonials reflect our
                    commitment to excellence and the transformative impact
                    we&apos;ve made on their businesses. Discover how we&apos;ve
                    helped them achieve their goals and drive growth.
                  </span>
                  <br></br>
                </span>
              </Fragment>
            )}
          </span>
        </div>
        <div className="thq-grid-2">
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div
                data-animated="true"
                className="thq-card home-testimonial-card"
              >
                <div className="home-testimonial-container02">
                  <img
                    alt={props.author1Alt}
                    src={props.author1Src}
                    className="home-testimonial-image"
                  />
                  <div className="home-testimonial-container03">
                    <strong className="home-testimonial-text02 thq-body-large">
                      {props.author1Name}
                    </strong>
                    <span className="home-testimonial-text03 thq-body-small">
                      {props.author1Position}
                    </span>
                  </div>
                </div>
                <span className="home-testimonial-text04 thq-body-small">
                  {props.review1}
                </span>
              </div>
            </div>
          </div>
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div
                data-animated="true"
                className="thq-card home-testimonial-card1"
              >
                <div className="home-testimonial-container04">
                  <img
                    alt={props.author2Alt}
                    src={props.author2Src}
                    className="home-testimonial-image1"
                  />
                  <div className="home-testimonial-container05">
                    <strong className="home-testimonial-text05 thq-body-large">
                      {props.author2Name}
                    </strong>
                    <span className="home-testimonial-text06 thq-body-small">
                      {props.author2Position}
                    </span>
                  </div>
                </div>
                <span className="home-testimonial-text07 thq-body-small">
                  {props.review2}
                </span>
              </div>
            </div>
          </div>
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div
                data-animated="true"
                className="thq-card home-testimonial-card2"
              >
                <div className="home-testimonial-container06">
                  <img
                    alt={props.author3Alt}
                    src={props.author3Src}
                    className="home-testimonial-image2"
                  />
                  <div className="home-testimonial-container07">
                    <strong className="home-testimonial-text08 thq-body-large">
                      {props.author3Name}
                    </strong>
                    <span className="home-testimonial-text09 thq-body-small">
                      {props.author3Position}
                    </span>
                  </div>
                </div>
                <span className="home-testimonial-text10 thq-body-small">
                  {props.review3}
                </span>
              </div>
            </div>
          </div>
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div
                data-animated="true"
                className="thq-card home-testimonial-card3"
              >
                <div className="home-testimonial-container08">
                  <img
                    alt={props.author4Alt}
                    src={props.author4Src}
                    className="home-testimonial-image3"
                  />
                  <div className="home-testimonial-container09">
                    <strong className="home-testimonial-text11 thq-body-large">
                      {props.author4Name}
                    </strong>
                    <span className="home-testimonial-text12 thq-body-small">
                      {props.author4Position}
                    </span>
                  </div>
                </div>
                <span className="home-testimonial-text13 thq-body-small">
                  {props.review4}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

HomeTestimonial.defaultProps = {
  author4Name: "Saman Sidhu",
  author4Src: "",
  author3Alt: "Image of Diya Sharma",
  text: undefined,
  author2Alt: "Image of Japinder Kaur",
  review3:
    "I am impressed with the level of professionalism and dedication shown by the SM Cloud. They have helped us reach our target audience effectively.",
  author1Position: "Founder, Sigma Immigration Consultancy",
  review2:
    "The team at SM Cloud is top-notch. They are responsive, creative, and always deliver results beyond our expectations.",
  review4:
    "SM Cloud has been instrumental in improving our online sales. Their strategic approach and attention to detail have made a significant impact on our business growth.",
  author3Name: "Nikhil Sharma\n",
  heading1: "Testimonials",
  author1Name: "Diya Sharma",
  author2Name: "Japinder Kaur",
  author1Alt: "Image of Diya Sharma",
  author2Position: "Owner, Auriga Immigration Inc.",
  author1Src: "",
  author3Src: "",
  author2Src: "",
  review1: "Highly recommend their services!",
  author4Position: "Realtor, Sidhu Realty Group",
  author3Position: "MD, NSJ Global",
  author4Alt: "Image of Saman Sidhu",
  rootClassName: "",
};

HomeTestimonial.propTypes = {
  author4Name: PropTypes.string,
  author4Src: PropTypes.string,
  author3Alt: PropTypes.string,
  text: PropTypes.element,
  author2Alt: PropTypes.string,
  review3: PropTypes.string,
  author1Position: PropTypes.string,
  review2: PropTypes.string,
  review4: PropTypes.string,
  author3Name: PropTypes.string,
  heading1: PropTypes.string,
  author1Name: PropTypes.string,
  author2Name: PropTypes.string,
  author1Alt: PropTypes.string,
  author2Position: PropTypes.string,
  author1Src: PropTypes.string,
  author3Src: PropTypes.string,
  author2Src: PropTypes.string,
  review1: PropTypes.string,
  author4Position: PropTypes.string,
  author3Position: PropTypes.string,
  author4Alt: PropTypes.string,
  rootClassName: PropTypes.string,
};

export default HomeTestimonial;
