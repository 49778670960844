import React, { Fragment } from "react";

import PropTypes from "prop-types";

import "./digital-m-header.css";

const DigitalMHeader = (props) => {
  return (
    <div
      className={`digital-m-header-header30 thq-section-padding ${props.rootClassName} `}
    >
      <img
        alt={props.imageAlt}
        src={props.imageSrc}
        className="digital-m-header-image"
      />
      <div className="digital-m-header-container"></div>
      <div className="digital-m-header-max-width thq-section-max-width">
        <div className="digital-m-header-content">
          <h1 className="digital-m-header-text">
            {props.text ?? (
              <Fragment>
                <h1 className="digital-m-header-text2 thq-heading-1">
                  Digital Marketing
                </h1>
              </Fragment>
            )}
          </h1>
        </div>
      </div>
    </div>
  );
};

DigitalMHeader.defaultProps = {
  text: undefined,
  text1: undefined,
  imageSrc: "/Digital_M_Bckg.png",
  imageAlt: "Digital Marketing Services",
  rootClassName: "",
};

DigitalMHeader.propTypes = {
  text: PropTypes.element,
  text1: PropTypes.element,
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
  rootClassName: PropTypes.string,
};

export default DigitalMHeader;
