import React, { Fragment } from "react";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import LegalContent from "../components/legal-content";
import Footer from "../components/footer";
import "./legal.css";

const Legal = (props) => {
  return (
    <div className="legal-container">
      <Helmet>
        <title>Legal - SM Cloud</title>
        <meta property="og:title" content="Legal - SM Cloud" />
      </Helmet>
      <Navbar></Navbar>
      <LegalContent
        text2={
          <Fragment>
            <span className="legal-text">
              SM Cloud Inc. is serious about the secrecy and safety of the
              credentials of its customers / clients / visitors. Our strong
              purpose is to keep their data safe. This privacy policy document
              contains a type of information that is collected by smcloud.net
              and how we use it.
            </span>
          </Fragment>
        }
        text21={
          <Fragment>
            <span className="legal-text01">
              Our privacy notice defines that you agree to our terms and
              conditions while visiting our website. We own the right to delete
              modify or change the content of the website or this Privacy Policy
              any time for any reason without prior announcement. If you have
              additional questions or require more information about our Privacy
              Policy, do not hesitate to contact us through email at
              info@smcloudsolutions.com
            </span>
          </Fragment>
        }
        text211={
          <Fragment>
            <span className="legal-text02">
              With the use of log files, SM Cloud Inc., records the information
              of visitors when they visit the website. These log files are
              basically used for analyzing the movement and collecting the
              demographic details of the visitor on the site. The information
              collected in the log files includes Internet Protocol Addresses,
              Browser Type, and Internet Service Provider, date and time stamp,
              number of clicks, and so on.
            </span>
          </Fragment>
        }
        text2111={
          <Fragment>
            <span className="legal-text03">
              We use Facebook pixel, Google global site tag, Google analytics
              and other required tags to improve the performance of our website
              and to re-market visitors on different ad platforms who visited
              our website in past. Email open and link clicks can be tracked by
              our email marketing softwares or other tools that we may need to
              check the response of our email.
            </span>
          </Fragment>
        }
        content51={
          <Fragment>
            <p className="legal-content5 thq-body-small">
              <br></br>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </p>
          </Fragment>
        }
        text21111={
          <Fragment>
            <span className="legal-text06">
              This privacy policy page is the sole proprietorship of SM Cloud
              Inc. and does not apply to other advertisers or websites. Thus, we
              are recommending you to consult the respective Privacy Policies of
              these third-party ad servers for more accurate information. It may
              include their individual practices and instructions about how to
              opt-out of certain options. You can choose to disable cookies
              through your individual browser options.
            </span>
          </Fragment>
        }
        text21112={
          <Fragment>
            <span className="legal-text07">
              Visiting our website means you agree on all the terms and
              conditions of our privacy policy.
            </span>
          </Fragment>
        }
        text21113={
          <Fragment>
            <span className="legal-text08">
              If you visit our website, requested a quote, filled our lead form
              or newsletter form or provided your details by any other way. We
              can contact you to tell you more about our services, show you
              remarketing ads or email you to tell you more about our services,
              offers or information.
            </span>
          </Fragment>
        }
        text21114={
          <Fragment>
            <span className="legal-text09">
              We own a copyright on this privacy policy. If any customers found
              overstepping the terms and conditions of this policy would be
              subject to legal action.
            </span>
          </Fragment>
        }
        text21115={
          <Fragment>
            <span className="legal-text10">
              This Privacy Policy can change at any time to either include or
              exclude clauses and SM Cloud Inc does not bear responsibility for
              updating Users on the same.
            </span>
          </Fragment>
        }
      ></LegalContent>
      <Footer rootClassName="footer-root-class-name6"></Footer>
    </div>
  );
};

export default Legal;
