import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './services-features.css'

const ServicesFeatures = (props) => {
  return (
    <div
      className={`services-features-layout349 thq-section-padding ${props.rootClassName} `}
    >
      <div className="services-features-max-width thq-section-max-width">
        <div className="services-features-container">
          <h2 className="services-features-text thq-heading-2">
            {props.heading1}
          </h2>
        </div>
        <div className="services-features-container1 thq-grid-auto-300">
          <div className="services-features-card thq-flex-column thq-card">
            <img
              alt={props.feature8ImageAlt}
              src={props.feature8ImageSrc}
              className="services-features-image thq-img-round thq-img-ratio-1-1"
            />
            <h1 className="services-features-text01 thq-heading-2">
              {props.feature8Title}
            </h1>
            <span className="services-features-text02 thq-body-small">
              {props.feature8Description}
            </span>
            <Link
              to="/contact-us"
              className="services-features-button thq-button-filled"
            >
              <span className="services-features-text03 thq-body-small">
                {props.action17}
              </span>
            </Link>
          </div>
          <div className="services-features-card1 thq-flex-column thq-card">
            <img
              alt={props.feature5ImageAlt}
              src={props.feature5ImageSrc}
              className="services-features-image1 thq-img-round thq-img-ratio-1-1"
            />
            <h1 className="services-features-text04 thq-heading-2">
              {props.feature5Title}
            </h1>
            <span className="services-features-text05 thq-body-small">
              {props.feature5Description}
            </span>
            <Link
              to="/contact-us"
              className="services-features-button1 thq-button-filled"
            >
              <span className="services-features-text06 thq-body-small">
                {props.action171}
              </span>
            </Link>
          </div>
          <div className="services-features-card2 thq-flex-column thq-card">
            <img
              alt={props.feature2ImageAlt}
              src={props.feature2ImageSrc}
              className="services-features-image2 thq-img-round thq-img-ratio-1-1"
            />
            <h2 className="services-features-text07 thq-heading-2">
              {props.feature2Title}
            </h2>
            <span className="services-features-text08 thq-body-small">
              {props.feature2Description}
            </span>
            <Link
              to="/contact-us"
              className="services-features-button2 thq-button-filled"
            >
              <span className="services-features-text09 thq-body-small">
                {props.action172}
              </span>
            </Link>
          </div>
          <div className="services-features-card3 thq-flex-column thq-card">
            <img
              alt={props.feature3ImageAlt}
              src={props.feature3ImageSrc}
              className="services-features-image3 thq-img-round thq-img-ratio-1-1"
            />
            <h1 className="services-features-text10 thq-heading-2">
              {props.feature3Title}
            </h1>
            <span className="services-features-text11 thq-body-small">
              {props.feature3Description}
            </span>
            <Link
              to="/contact-us"
              className="services-features-button3 thq-button-filled"
            >
              <span className="services-features-text12 thq-body-small">
                {props.action173}
              </span>
            </Link>
          </div>
          <div className="services-features-card4 thq-flex-column thq-card">
            <img
              alt={props.feature4ImageAlt}
              src={props.feature4ImageSrc}
              className="services-features-image4 thq-img-round thq-img-ratio-1-1"
            />
            <h1 className="services-features-text13 thq-heading-2">
              {props.feature4Title}
            </h1>
            <span className="services-features-text14 thq-body-small">
              {props.feature4Description}
            </span>
            <Link
              to="/contact-us"
              className="services-features-button4 thq-button-filled"
            >
              <span className="services-features-text15 thq-body-small">
                {props.action177}
              </span>
            </Link>
          </div>
          <div className="services-features-card5 thq-flex-column thq-card">
            <img
              alt={props.feature6ImageAlt}
              src={props.feature6ImageSrc}
              className="services-features-image5 thq-img-round thq-img-ratio-1-1"
            />
            <h1 className="services-features-text16 thq-heading-2">
              {props.feature6Title}
            </h1>
            <span className="services-features-text17 thq-body-small">
              {props.feature6Description}
            </span>
            <Link
              to="/contact-us"
              className="services-features-button5 thq-button-filled"
            >
              <span className="services-features-text18 thq-body-small">
                {props.action176}
              </span>
            </Link>
          </div>
          <div className="services-features-card6 thq-flex-column thq-card">
            <img
              alt={props.feature1ImageAlt}
              src={props.feature1ImageSrc}
              className="services-features-image6 thq-img-round thq-img-ratio-1-1"
            />
            <h2>
              {props.heading ?? (
                <Fragment>
                  <h2 className="services-features-text25 thq-heading-2">
                    SEO
                  </h2>
                </Fragment>
              )}
            </h2>
            <span className="services-features-text20 thq-body-small">
              {props.feature1Description}
            </span>
            <Link
              to="/contact-us"
              className="services-features-button6 thq-button-filled"
            >
              <span className="services-features-text21 thq-body-small">
                {props.action175}
              </span>
            </Link>
          </div>
          <div className="services-features-card7 thq-flex-column thq-card">
            <img
              alt={props.feature7ImageAlt}
              src={props.feature7ImageSrc}
              className="services-features-image7 thq-img-round thq-img-ratio-1-1"
            />
            <h1 className="services-features-text22 thq-heading-2">
              {props.feature7Title}
            </h1>
            <span className="services-features-text23 thq-body-small">
              {props.feature7Description}
            </span>
            <Link
              to="/contact-us"
              className="services-features-button7 thq-button-filled"
            >
              <span className="services-features-text24 thq-body-small">
                {props.action174}
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

ServicesFeatures.defaultProps = {
  feature6ImageAlt: 'Client Testimonials Icon',
  feature7Description:
    'Choose from a variety of pricing plans that suit your budget and needs.',
  action172: 'Know more...',
  feature8Description:
    'Streamline your cloud infrastructure with our advanced optimization services, ensuring cost-efficiency and enhanced performance for your business.',
  feature2Title: 'Social Media Marketing',
  feature3Description:
    'Run targeted ad campaigns to drive instant traffic and conversions.',
  heading1: 'Our Key Services',
  action171: 'Know more...',
  feature4Title: 'Website Design & Development',
  feature1Description:
    'Boost your online visibility and drive organic traffic with our expert SEO services. We optimize your website to achieve higher search engine rankings and improved user engagement.',
  feature5ImageAlt: 'Customized Plans Icon',
  feature2ImageAlt: 'Social Media Icon',
  feature7ImageAlt: 'Pricing Plans Icon',
  heading: undefined,
  feature1ImageSrc:
    'https://images.unsplash.com/photo-1709281847802-9aef10b6d4bf?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDMyfHxvcHRpbWl6ZXxlbnwwfHx8fDE3MjI5MTk4Njd8MA&ixlib=rb-4.0.3&w=1500',
  rootClassName: '',
  feature6Description:
    'Read what our satisfied clients have to say about our services.',
  feature1ImageAlt: 'SEO Icon',
  feature5Title: 'Cloud Migration',
  feature2Description:
    'Engage with your audience on social media platforms to increase brand awareness.',
  feature3Title: 'Pay-Per-Click  Advertising',
  action175: 'Know more...',
  action173: 'Know more...',
  feature2ImageSrc:
    'https://images.unsplash.com/photo-1674027001860-f9e3a94f4084?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDIyfHxvcHRpbWl6ZXxlbnwwfHx8fDE3MjI5MTk3Nzl8MA&ixlib=rb-4.0.3&w=1500',
  feature4Description:
    'Create a visually appealing and user-friendly website to enhance user experience.',
  feature8ImageSrc:
    'https://images.unsplash.com/photo-1494435619026-9e21da76be75?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDE2fHxvcHRpbWl6ZXxlbnwwfHx8fDE3MjI5MTk3Nzl8MA&ixlib=rb-4.0.3&w=1500',
  feature6Title: 'Mobile App Development',
  feature3ImageSrc:
    'https://images.unsplash.com/photo-1686061593269-420785fb8fa0?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDQwfHxvcHRpbWl6ZXxlbnwwfHx8fDE3MjI5MTk4Njd8MA&ixlib=rb-4.0.3&w=1500',
  feature7ImageSrc:
    'https://images.unsplash.com/photo-1559526324-4b87b5e36e44?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDU4fHxvcHRpbWl6ZXxlbnwwfHx8fDE3MjI5MTk4OTR8MA&ixlib=rb-4.0.3&w=1500',
  feature6ImageSrc:
    'https://images.unsplash.com/photo-1563986768609-322da13575f3?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDI1fHxvcHRpbWl6ZXxlbnwwfHx8fDE3MjI5MTk4Njd8MA&ixlib=rb-4.0.3&w=1500',
  action176: 'Know more...',
  action174: 'Know more...',
  feature3ImageAlt: 'PPC Icon',
  feature8Title: 'Cloud Optimization',
  feature7Title: ' Domain Management',
  feature5ImageSrc:
    'https://images.unsplash.com/photo-1588064578354-c1e28d429246?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDE1fHxvcHRpbWl6ZXxlbnwwfHx8fDE3MjI5MTk3Nzl8MA&ixlib=rb-4.0.3&w=1500',
  feature4ImageAlt: 'Website Design Icon',
  action177: 'Know more...',
  feature4ImageSrc:
    'https://images.unsplash.com/photo-1563986768817-257bf91c5753?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDU1fHxvcHRpbWl6ZXxlbnwwfHx8fDE3MjI5MTk4OTR8MA&ixlib=rb-4.0.3&w=1500',
  feature8ImageAlt: 'Support Icon',
  feature5Description:
    'Specializing in seamless cloud migration, we help businesses transition to cloud platforms with minimal disruption, ensuring enhanced scalability and efficiency.',
  action17: 'Know more...',
}

ServicesFeatures.propTypes = {
  feature6ImageAlt: PropTypes.string,
  feature7Description: PropTypes.string,
  action172: PropTypes.string,
  feature8Description: PropTypes.string,
  feature2Title: PropTypes.string,
  feature3Description: PropTypes.string,
  heading1: PropTypes.string,
  action171: PropTypes.string,
  feature4Title: PropTypes.string,
  feature1Description: PropTypes.string,
  feature5ImageAlt: PropTypes.string,
  feature2ImageAlt: PropTypes.string,
  feature7ImageAlt: PropTypes.string,
  heading: PropTypes.element,
  feature1ImageSrc: PropTypes.string,
  rootClassName: PropTypes.string,
  feature6Description: PropTypes.string,
  feature1ImageAlt: PropTypes.string,
  feature5Title: PropTypes.string,
  feature2Description: PropTypes.string,
  feature3Title: PropTypes.string,
  action175: PropTypes.string,
  action173: PropTypes.string,
  feature2ImageSrc: PropTypes.string,
  feature4Description: PropTypes.string,
  feature8ImageSrc: PropTypes.string,
  feature6Title: PropTypes.string,
  feature3ImageSrc: PropTypes.string,
  feature7ImageSrc: PropTypes.string,
  feature6ImageSrc: PropTypes.string,
  action176: PropTypes.string,
  action174: PropTypes.string,
  feature3ImageAlt: PropTypes.string,
  feature8Title: PropTypes.string,
  feature7Title: PropTypes.string,
  feature5ImageSrc: PropTypes.string,
  feature4ImageAlt: PropTypes.string,
  action177: PropTypes.string,
  feature4ImageSrc: PropTypes.string,
  feature8ImageAlt: PropTypes.string,
  feature5Description: PropTypes.string,
  action17: PropTypes.string,
}

export default ServicesFeatures
