import React, { Fragment } from "react";

import PropTypes from "prop-types";

import "./legal-content.css";

const LegalContent = (props) => {
  return (
    <div className="legal-content-container thq-section-padding">
      <div className="legal-content-max-width thq-flex-column thq-section-max-width">
        <div className="legal-content-content thq-flex-column">
          <ul>
            <li className="legal-content-li thq-flex-column list-item">
              <h2 className="legal-content-heading1 thq-heading-2">
                {props.heading1}
              </h2>
              <span>
                {props.text2 ?? (
                  <Fragment>
                    <span className="legal-content-text26">
                      SM Cloud Inc. is serious about the secrecy and safety of
                      the credentials of its customers / clients / visitors. Our
                      strong purpose is to keep their data safe. This privacy
                      policy document contains a type of information that is
                      collected by smcloud.net and how we use it.
                    </span>
                  </Fragment>
                )}
              </span>
              <span>
                {props.text21 ?? (
                  <Fragment>
                    <span className="legal-content-text34">
                      Our privacy notice defines that you agree to our terms and
                      conditions while visiting our website. We own the right to
                      delete modify or change the content of the website or this
                      Privacy Policy any time for any reason without prior
                      announcement. If you have additional questions or require
                      more information about our Privacy Policy, do not hesitate
                      to contact us through email at info@smcloudsolutions.com
                    </span>
                  </Fragment>
                )}
              </span>
              <ul className="legal-content-ul1 thq-flex-column">
                <li className="list-item">
                  <h3 className="thq-heading-3">{props.heading3}</h3>
                  <span>
                    {props.text211 ?? (
                      <Fragment>
                        <span className="legal-content-text36">
                          With the use of log files, SM Cloud Inc., records the
                          information of visitors when they visit the website.
                          These log files are basically used for analyzing the
                          movement and collecting the demographic details of the
                          visitor on the site. The information collected in the
                          log files includes Internet Protocol Addresses,
                          Browser Type, and Internet Service Provider, date and
                          time stamp, number of clicks, and so on.
                        </span>
                      </Fragment>
                    )}
                  </span>
                </li>
                <li className="list-item">
                  <h3 className="thq-heading-3">{props.heading4}</h3>
                  <span>
                    {props.text2111 ?? (
                      <Fragment>
                        <span className="legal-content-text30">
                          We use Facebook pixel, Google global site tag, Google
                          analytics and other required tags to improve the
                          performance of our website and to re-market visitors
                          on different ad platforms who visited our website in
                          past. Email open and link clicks can be tracked by our
                          email marketing softwares or other tools that we may
                          need to check the response of our email.
                        </span>
                      </Fragment>
                    )}
                  </span>
                </li>
                <li className="list-item">
                  <h3 className="thq-heading-3">{props.heading5}</h3>
                  <span>
                    {props.text21111 ?? (
                      <Fragment>
                        <span className="legal-content-text32">
                          This privacy policy page is the sole proprietorship of
                          SM Cloud Inc. and does not apply to other advertisers
                          or websites. Thus, we are recommending you to consult
                          the respective Privacy Policies of these third-party
                          ad servers for more accurate information. It may
                          include their individual practices and instructions
                          about how to opt-out of certain options. You can
                          choose to disable cookies through your individual
                          browser options.
                        </span>
                      </Fragment>
                    )}
                  </span>
                  <p>
                    {props.content51 ?? (
                      <Fragment>
                        <p className="legal-content-content51 thq-body-small">
                          <br></br>
                          <span>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: " ",
                              }}
                            />
                          </span>
                        </p>
                      </Fragment>
                    )}
                  </p>
                </li>
                <li className="list-item">
                  <h3 className="thq-heading-3">{props.heading6}</h3>
                  <span>
                    {props.text21112 ?? (
                      <Fragment>
                        <span className="legal-content-text29">
                          Visiting our website means you agree on all the terms
                          and conditions of our privacy policy.
                        </span>
                      </Fragment>
                    )}
                  </span>
                </li>
                <li className="list-item">
                  <h3 className="thq-heading-3">{props.heading62}</h3>
                  <span>
                    {props.text21113 ?? (
                      <Fragment>
                        <span className="legal-content-text33">
                          If you visit our website, requested a quote, filled
                          our lead form or newsletter form or provided your
                          details by any other way. We can contact you to tell
                          you more about our services, show you remarketing ads
                          or email you to tell you more about our services,
                          offers or information.
                        </span>
                      </Fragment>
                    )}
                  </span>
                </li>
                <li className="list-item">
                  <h3 className="thq-heading-3">{props.heading61}</h3>
                  <span>
                    {props.text21114 ?? (
                      <Fragment>
                        <span className="legal-content-text35">
                          We own a copyright on this privacy policy. If any
                          customers found overstepping the terms and conditions
                          of this policy would be subject to legal action.
                        </span>
                      </Fragment>
                    )}
                  </span>
                </li>
              </ul>
            </li>
          </ul>
          <span>
            {props.text21115 ?? (
              <Fragment>
                <span className="legal-content-text31">
                  This Privacy Policy can change at any time to either include
                  or exclude clauses and SM Cloud Inc does not bear
                  responsibility for updating Users on the same.
                </span>
              </Fragment>
            )}
          </span>
        </div>
        <div className="legal-content-content1 thq-flex-column">
          <ul>
            <li className="legal-content-li07 thq-flex-column list-item">
              <h2 className="legal-content-heading7 thq-heading-2">
                {props.heading7}
              </h2>
              <ul className="legal-content-ul3 thq-flex-column">
                <li className="list-item">
                  <h3 className="legal-content-heading8 thq-heading-3">
                    {props.heading8}
                  </h3>
                  <div className="legal-content-container1">
                    <li className="legal-content-li09 list-item">
                      <span>
                        SM Cloud agrees to create written material for insertion
                        on Client’s website upon the appeal of the Client and
                        per the approved terms.
                      </span>
                    </li>
                    <li className="legal-content-li10 list-item">
                      <span>
                        SM Cloud agrees to use rational care to ensure that all
                        facts and statements in the work are correct and that
                        the work does not trespass upon any copyright, or any
                        other right of a third party.
                      </span>
                    </li>
                    <li className="legal-content-li11 list-item">
                      <span>
                        SM Cloud admits that Client has the right to edit the
                        work in a time frame agreed and that Rankon Technologies
                        will collaborate with Client in editing and rereading
                        the work erstwhile to publication.
                      </span>
                    </li>
                    <li className="legal-content-li12 list-item">
                      <span>
                        SM Cloud agrees to use rational care to ensure that all
                        facts and statements in the work are correct and that
                        the work does not trespass upon any copyright, or any
                        other right of a third party.
                      </span>
                    </li>
                  </div>
                </li>
                <li className="list-item">
                  <h3 className="legal-content-heading9 thq-heading-3">
                    {props.heading9}
                  </h3>
                  <div className="legal-content-container2">
                    <li className="legal-content-li14 list-item">
                      <span>
                        SM Cloud approves design and develop a website at
                        Client’s request and will deliver the job by to the
                        settled conditions.
                      </span>
                    </li>
                    <li className="legal-content-li15 list-item">
                      <span>
                        Client is solely responsible for describing the detailed
                        structure, number of pages required, and other relevant
                        terms and conditions.
                      </span>
                    </li>
                    <li className="legal-content-li16 list-item">
                      <span>
                        Client agrees to supply the SM Cloud Inc. with
                        satisfactory photography or imagery to use in the
                        design.
                      </span>
                    </li>
                    <li className="legal-content-li17 list-item">
                      <span>
                        Client is responsible for obtaining and buying a domain
                        name. SM Cloud Inc. may at its discretion instruct
                        Client on suitable names.
                      </span>
                    </li>
                    <li className="legal-content-li18 list-item">
                      <span>
                        Client hereby admits that SM Cloud Inc. is not
                        accountable for any errors, grammatical or otherwise
                        found in the material provided by client.
                      </span>
                    </li>
                    <li className="legal-content-li19 list-item">
                      <span>
                        SM Cloud agrees to resolve any errors or implement
                        revisions requested by Client. Client agrees that a
                        maximum of three revisions are available(depending on
                        package). Major changes in design won’t be done in
                        revisions.
                      </span>
                    </li>
                    <li className="legal-content-li20 list-item">
                      <span>
                        Client acknowledges that any change requests after final
                        approval has been given to SM Cloud Inc. or after
                        website is live will be charged. SM Cloud Inc. will not
                        begin work on following change requests until full
                        payment has been received.
                      </span>
                    </li>
                    <li className="legal-content-li21 list-item">
                      <span>
                        Client admits that SM Cloud Inc. will add a “Web Design”
                        by “SM Cloud” link to the footnote section of each
                        website they work on. This is branding of SM Cloud Inc.
                        and to eliminate it will charge.
                      </span>
                    </li>
                    <li className="legal-content-li22 list-item">
                      <span>
                        Due to business nature, SM Cloud Inc. will not be liable
                        if your website got hacked, lost rankings or errors etc.
                      </span>
                    </li>
                    <li className="legal-content-li23 list-item">
                      <span>
                        Clients can cancel website development or digital
                        marketing project if the team of SM Cloud Inc. has not
                        started work and won’t accept any cancellation after 1
                        calendar day.
                      </span>
                    </li>
                    <li className="legal-content-li24 list-item">
                      <span>
                        Once the website is live then paid support will be
                        provided at the rate of $50 + Taxes.
                      </span>
                    </li>
                  </div>
                </li>
                <li className="list-item">
                  <h3 className="legal-content-heading91 thq-heading-3">
                    {props.heading91}
                  </h3>
                  <div className="legal-content-container3">
                    <li className="legal-content-li26 list-item">
                      <span>
                        We try to create unique logos and provide our
                        recommendations. Our logo designs are built based on
                        research &amp; concept building &amp; we assure you of
                        an alternate logo design if a Trade Mark on the logo is
                        rejected due to any reason.
                      </span>
                    </li>
                    <li className="legal-content-li27 list-item">
                      <span>
                        If you face any problem during trademark registration
                        then you are free to contact us and we will offer you
                        some other variations that you can chose. However, we do
                        not have access to all the existing logos like the
                        trademark registry has and it is nearly impossible to be
                        100% certain that the chosen design is in no way a loose
                        match of an existing design.
                      </span>
                    </li>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

LegalContent.defaultProps = {
  heading1: "Privacy Policy",
  text2: undefined,
  heading3: "Log Files",
  content51: undefined,
  heading8: "Content Writing",
  text21112: undefined,
  heading61: "Copyright",
  heading7: "Terms of service",
  text2111: undefined,
  heading91: "Logo & Graphic Design",
  heading62: "Contact",
  content2:
    "Our privacy notice defines that you agree to our terms and conditions while visiting our website.  We own the right to delete modify or change the content of the website or this Privacy Policy any time for any reason without prior announcement. If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us through email at info@smcloudsolutions.com",
  heading5: "Third Party Privacy Policies",
  text21115: undefined,
  heading4: "Tracking",
  heading9: "Web Design & Development",
  text21111: undefined,
  text21113: undefined,
  text21: undefined,
  heading6: "Consent",
  text21114: undefined,
  text211: undefined,
};

LegalContent.propTypes = {
  heading1: PropTypes.string,
  text2: PropTypes.element,
  heading3: PropTypes.string,
  content51: PropTypes.element,
  heading8: PropTypes.string,
  text21112: PropTypes.element,
  heading61: PropTypes.string,
  heading7: PropTypes.string,
  text2111: PropTypes.element,
  heading91: PropTypes.string,
  heading62: PropTypes.string,
  content2: PropTypes.string,
  heading5: PropTypes.string,
  text21115: PropTypes.element,
  heading4: PropTypes.string,
  heading9: PropTypes.string,
  text21111: PropTypes.element,
  text21113: PropTypes.element,
  text21: PropTypes.element,
  heading6: PropTypes.string,
  text21114: PropTypes.element,
  text211: PropTypes.element,
};

export default LegalContent;
