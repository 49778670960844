import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './pricing-faq.css'

const PricingFAQ = (props) => {
  const [faq3Visible, setFaq3Visible] = useState(false)
  const [faq4Visible, setFaq4Visible] = useState(false)
  const [faq2Visible, setFaq2Visible] = useState(false)
  const [faq1Visible, setFaq1Visible] = useState(false)
  const [faq5Visible, setFaq5Visible] = useState(false)
  return (
    <div className="pricing-faq-faq8 thq-section-padding">
      <div className="pricing-faq-max-width thq-section-max-width">
        <div className="thq-flex-column thq-section-max-width pricing-faq-container">
          <div className="pricing-faq-section-title">
            <div className="pricing-faq-content">
              <h2 className="pricing-faq-text thq-heading-2">
                {props.heading1}
              </h2>
              <p className="pricing-faq-text1 thq-body-large">
                {props.content1}
              </p>
            </div>
          </div>
          <div className="pricing-faq-list">
            <div className="pricing-faq-faq1">
              <div
                onClick={() => setFaq1Visible(!faq1Visible)}
                className="pricing-faq-trigger"
              >
                <p className="pricing-faq-faq1-question thq-body-large">
                  {props.faq1Question}
                </p>
                <div className="pricing-faq-icons-container">
                  {!faq1Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="pricing-faq-icon">
                        <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  )}
                  {faq1Visible && (
                    <div>
                      <svg
                        viewBox="0 0 1024 1024"
                        className="pricing-faq-icon02"
                      >
                        <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  )}
                </div>
              </div>
              {faq1Visible && (
                <div className="pricing-faq-container03">
                  <span className="thq-body-small">
                    This is hard to say, as it varies greatly by client. A
                    custom microsite can take as little as four weeks and a
                    large, complex B2B website can take three months. Similar to
                    cost, the timeframe for implementation varies on complexity
                    and the number of web pages and blog posts. In most cases,
                    we are waiting on the client and the client is not waiting
                    on us.
                  </span>
                </div>
              )}
            </div>
            <div className="pricing-faq-faq2">
              <div
                onClick={() => setFaq2Visible(!faq2Visible)}
                className="pricing-faq-trigger1"
              >
                <p className="pricing-faq-faq2-question thq-body-large">
                  {props.faq2Question}
                </p>
                <div className="pricing-faq-icons-container1">
                  {!faq2Visible && (
                    <div>
                      <svg
                        viewBox="0 0 1024 1024"
                        className="pricing-faq-icon04"
                      >
                        <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  )}
                  {faq2Visible && (
                    <div>
                      <svg
                        viewBox="0 0 1024 1024"
                        className="pricing-faq-icon06"
                      >
                        <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  )}
                </div>
              </div>
              {faq2Visible && (
                <div className="pricing-faq-container06">
                  <span className="thq-body-small">
                    {' '}
                    Website development focuses on creating and optimizing
                    websites for desktop and laptop browsers, emphasizing
                    cross-browser compatibility and responsive design. Mobile
                    development, on the other hand, involves building apps
                    specifically for smartphones and tablets, often requiring
                    specialized design considerations for touch interfaces and
                    varying screen sizes. While websites are accessible via web
                    browsers, mobile apps are downloaded and installed directly
                    on devices.
                  </span>
                </div>
              )}
            </div>
            <div className="pricing-faq-faq3">
              <div
                onClick={() => setFaq3Visible(!faq3Visible)}
                className="pricing-faq-trigger2"
              >
                <p className="pricing-faq-faq2-question1 thq-body-large">
                  {props.faq3Question}
                </p>
                <div className="pricing-faq-icons-container2">
                  {!faq3Visible && (
                    <div>
                      <svg
                        viewBox="0 0 1024 1024"
                        className="pricing-faq-icon08"
                      >
                        <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  )}
                  {faq3Visible && (
                    <div>
                      <svg
                        viewBox="0 0 1024 1024"
                        className="pricing-faq-icon10"
                      >
                        <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  )}
                </div>
              </div>
              {faq3Visible && (
                <div className="pricing-faq-container09">
                  <span className="thq-body-small">
                    We work with leading cloud service providers including
                    Amazon Web Services (AWS), Microsoft Azure, Google Cloud
                    Platform (GCP), and IBM Cloud. The best choice for you
                    depends on your specific needs: AWS is great for scalability
                    and a broad range of services; Azure excels in hybrid cloud
                    solutions and integration with Microsoft products; GCP
                    offers strong data analytics and machine learning
                    capabilities; and IBM Cloud is known for its enterprise
                    solutions and AI services. We can help you evaluate your
                    requirements and select the best provider for your business.
                  </span>
                </div>
              )}
            </div>
            <div className="pricing-faq-faq4">
              <div
                onClick={() => setFaq4Visible(!faq4Visible)}
                className="pricing-faq-trigger3"
              >
                <p className="pricing-faq-faq2-question2 thq-body-large">
                  {props.faq4Question}
                </p>
                <div className="pricing-faq-icons-container3">
                  {!faq4Visible && (
                    <div>
                      <svg
                        viewBox="0 0 1024 1024"
                        className="pricing-faq-icon12"
                      >
                        <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  )}
                  {faq4Visible && (
                    <div>
                      <svg
                        viewBox="0 0 1024 1024"
                        className="pricing-faq-icon14"
                      >
                        <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  )}
                </div>
              </div>
              {faq4Visible && (
                <div className="pricing-faq-container12">
                  <span className="thq-body-small">
                    The very first step we take is to do the website health
                    checkup that is technical site review (Auditing), to check
                    what all is good for the search engines and keyword phrase
                    strategy. Within the first 7 days, we optimize the website
                    structure, generating and implementing webmaster and
                    analytics codes, creating social media profiles and
                    submitting to all engines. Within the first 45 days, the
                    search engine began to show results of our preliminary
                    efforts.
                  </span>
                </div>
              )}
            </div>
            <div className="pricing-faq-faq5">
              <div
                onClick={() => setFaq5Visible(!faq5Visible)}
                className="pricing-faq-trigger4"
              >
                <p className="pricing-faq-faq1-question1 thq-body-large">
                  {props.faq1Question1}
                </p>
                <div className="pricing-faq-icons-container4">
                  {!faq5Visible && (
                    <div>
                      <svg
                        viewBox="0 0 1024 1024"
                        className="pricing-faq-icon16"
                      >
                        <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  )}
                  {faq5Visible && (
                    <div>
                      <svg
                        viewBox="0 0 1024 1024"
                        className="pricing-faq-icon18"
                      >
                        <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  )}
                </div>
              </div>
              {faq5Visible && (
                <div className="pricing-faq-container15">
                  <span className="thq-body-small">
                    Lorem ipsum dolor sit amet. Est eaque sint ut blanditiis
                    sunt aut deleniti illum non repudiandae voluptatem. Aut
                    praesentium doloribus qui distinctio neque ut unde
                    temporibus. Cum exercitationem eveniet in omnis animi in
                    corporis nulla. Sed tempora excepturi et voluptatem modi et
                    exercitationem voluptate cum illum quisquam 33 quia
                    blanditiis eos minus consequatur.Ut neque quam qui
                    dignissimos voluptates ut voluptate totam aut consequuntur
                    quod. Ut voluptas incidunt ut fuga nostrum ut quaerat enim
                    eum earum tenetur? Est esse harum et Quis officiis et enim
                    amet.Et minima tempore et neque voluptatem eos amet officiis
                    et temporibus Quis. Et suscipit esse id nemo sunt At nihil
                    earum et consequatur fuga aut sapiente voluptate est
                    cupiditate esse non dolor cumque. Ut obcaecati recusandae et
                    beatae quae qui doloremque eligendi sit eaque labore.
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="pricing-faq-content1 thq-flex-column">
          <div className="pricing-faq-content2">
            <h2 className="pricing-faq-text7 thq-heading-2">
              {props.heading2}
            </h2>
            <p className="pricing-faq-text8 thq-body-large">{props.content2}</p>
          </div>
          <Link
            to="/contact-us"
            className="pricing-faq-button thq-button-filled"
          >
            <span className="thq-body-small">{props.action}</span>
          </Link>
        </div>
      </div>
    </div>
  )
}

PricingFAQ.defaultProps = {
  faq4Question: 'How long does it take to achieve results organically?',
  content1:
    'We offer services such as SEO, social media marketing, and PPC advertising to help businesses improve their online presence.',
  heading2: 'Still have a question?',
  content2:
    'We create customized solutions and plans by understanding the unique needs and goals of each client, conducting thorough research, and leveraging our expertise in digital space.',
  faq1Question: 'How long will it take to design and build my new website?',
  faq2Question: 'How does website development differ from mobile development?',
  heading1: 'Frequently Asked Questions',
  faq3Question:
    'What all cloud service providers do you work with, and which is the best for me?',
  faq1Question1: 'Can I get SEO done for a month?',
  action: 'Contact',
}

PricingFAQ.propTypes = {
  faq4Question: PropTypes.string,
  content1: PropTypes.string,
  heading2: PropTypes.string,
  content2: PropTypes.string,
  faq1Question: PropTypes.string,
  faq2Question: PropTypes.string,
  heading1: PropTypes.string,
  faq3Question: PropTypes.string,
  faq1Question1: PropTypes.string,
  action: PropTypes.string,
}

export default PricingFAQ
