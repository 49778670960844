import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './about-comp-stats.css'

const AboutCompStats = (props) => {
  return (
    <div className="about-comp-stats-about-comp-stats thq-section-padding">
      <div className="about-comp-stats-max-width">
        <div className="about-comp-stats-container">
          <h2 className="about-comp-stats-title">
            {props.title ?? (
              <Fragment>
                <h2 className="about-comp-stats-text15 thq-heading-2">
                  Company Stats
                </h2>
              </Fragment>
            )}
          </h2>
          <span>
            {props.content ?? (
              <Fragment>
                <span className="about-comp-stats-text17 thq-body-small">
                  Our results in numbers
                </span>
              </Fragment>
            )}
          </span>
        </div>
        <div className="about-comp-stats-container1">
          <div className="about-comp-stats-container2">
            <h2 className="about-comp-stats-text">
              {props.heading ?? (
                <Fragment>
                  <h2 className="about-comp-stats-text23 thq-heading-2">2+</h2>
                </Fragment>
              )}
            </h2>
            <span>
              {props.text ?? (
                <Fragment>
                  <span className="about-comp-stats-text18 thq-body-small">
                    Years of Experience
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="about-comp-stats-container3">
            <h2 className="about-comp-stats-text02">
              {props.heading1 ?? (
                <Fragment>
                  <h2 className="about-comp-stats-text28 thq-heading-2">80+</h2>
                </Fragment>
              )}
            </h2>
            <span>
              {props.text1 ?? (
                <Fragment>
                  <span className="about-comp-stats-text16 thq-body-small">
                    Completed Digital Marketing Projects
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="about-comp-stats-container4">
            <h2 className="about-comp-stats-text04">
              {props.heading2 ?? (
                <Fragment>
                  <h2 className="about-comp-stats-text29 thq-heading-2">20+</h2>
                </Fragment>
              )}
            </h2>
            <span>
              {props.text2 ?? (
                <Fragment>
                  <span className="about-comp-stats-text27 thq-body-small">
                    Cloud Services
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="about-comp-stats-container5">
            <h2 className="about-comp-stats-text06">
              {props.heading3 ?? (
                <Fragment>
                  <h2 className="about-comp-stats-text25 thq-heading-2">15</h2>
                </Fragment>
              )}
            </h2>
            <span className="about-comp-stats-text07">
              {props.text3 ?? (
                <Fragment>
                  <span className="about-comp-stats-text20 thq-body-small">
                    Active clients on 1 - year contract
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="about-comp-stats-container6">
            <h2 className="about-comp-stats-text08">
              {props.heading4 ?? (
                <Fragment>
                  <h2 className="about-comp-stats-text14 thq-heading-2">
                    100%
                  </h2>
                </Fragment>
              )}
            </h2>
            <span>
              {props.text4 ?? (
                <Fragment>
                  <span className="about-comp-stats-text24 thq-body-small">
                    On-time Project Delivery
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="about-comp-stats-container7">
            <h2 className="about-comp-stats-text10">
              {props.heading5 ?? (
                <Fragment>
                  <h2 className="about-comp-stats-text26 thq-heading-2">95%</h2>
                </Fragment>
              )}
            </h2>
            <span>
              {props.text5 ?? (
                <Fragment>
                  <span className="about-comp-stats-text22 thq-body-small">
                    Client Satisfaction Rate
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="about-comp-stats-container8">
            <h2 className="about-comp-stats-text12">
              {props.heading6 ?? (
                <Fragment>
                  <h2 className="about-comp-stats-text19 thq-heading-2">20+</h2>
                </Fragment>
              )}
            </h2>
            <span>
              {props.text6 ?? (
                <Fragment>
                  <span className="about-comp-stats-text21 thq-body-small">
                    Team Members
                  </span>
                </Fragment>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

AboutCompStats.defaultProps = {
  heading4: undefined,
  title: undefined,
  text1: undefined,
  content: undefined,
  text: undefined,
  heading6: undefined,
  text3: undefined,
  text6: undefined,
  text5: undefined,
  heading: undefined,
  text4: undefined,
  heading3: undefined,
  heading5: undefined,
  text2: undefined,
  heading1: undefined,
  heading2: undefined,
}

AboutCompStats.propTypes = {
  heading4: PropTypes.element,
  title: PropTypes.element,
  text1: PropTypes.element,
  content: PropTypes.element,
  text: PropTypes.element,
  heading6: PropTypes.element,
  text3: PropTypes.element,
  text6: PropTypes.element,
  text5: PropTypes.element,
  heading: PropTypes.element,
  text4: PropTypes.element,
  heading3: PropTypes.element,
  heading5: PropTypes.element,
  text2: PropTypes.element,
  heading1: PropTypes.element,
  heading2: PropTypes.element,
}

export default AboutCompStats
